
import { Prop, Options, Vue, Watch } from "vue-property-decorator";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import Or_tableClaim from "@/components/organisms/Or_tableClaim.vue";
import { Cl_common } from "@/functions/Cl_common";

import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersgetReq } from "mediadept-common/src/common/dto/DtoMastersGetReq";
import { DtoMastersGetRes } from "mediadept-common/src/common/dto/DtoMastersGetRes";

import { Cl_attendanceImple } from "@/functions/Cl_attendanceImple";
import { DtoAttendancesGetReqImple } from "mediadept-common/src/common/dto/DtoAttendancesGetReqImple";
import { DtoAttendancesGetResImple } from "mediadept-common/src/common/dto/DtoAttendancesGetResImple";
import { DtoAttendancesPutReqImple } from "mediadept-common/src/common/dto/DtoAttendancesPutReqImple";
import { DtoAttendancesPutResImple } from "mediadept-common/src/common/dto/DtoAttendancesPutResImple";
import Mo_tableAttendance_row from "@/components/molecules/Mo_tableAttendance_row.vue";
import Or_map from "@/components/organisms/Or_map.vue";

import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import store from "@/store";

// バリデーション
import * as Yup from "yup";
import {
  CONST_ATTENDANCE_GETMODE_NORMAL,
  CONST_ATTENDANCE_TIME_DIFFERENCE,
  CONST_AUTHORITY_STAFF_NYURYOKUSYA,
} from "mediadept-common/src/common/constant/Constants";
import { DtoFileMastersGetReqImple } from "mediadept-common/src/common/dto/DtoFileMastersGetReqImple";
import { Cl_filemaster } from "@/functions/Cl_filemaster";
import { DtoFileReportsGetReqImple } from "mediadept-common/src/common/dto/DtoFileReportsGetReqImple";
import { Cl_filereport } from "@/functions/Cl_filereport";
import { DtoOutputqueuesGetReqImple } from "mediadept-common/src/common/dto/DtoOutputqueuesGetReqImple";
import { DtoOutputqueuesGetResImple } from "mediadept-common/src/common/dto/DtoOutputqueuesGetResImple";
import { Cl_outputqueue } from "@/functions/Cl_outputqueue";

@Options({
  components: {
    vSelect,
    Mo_modal_alert,
    Mo_tableAttendance_row,
    Or_map,
  },
})
export default class Attendanceedit extends Vue {
  //----------------------
  //バリデーション
  //----------------------
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoAttendancesGetResImple();
  delegate4list = new DtoMastersGetRes();
  btnDisable = false;
  isNuryokusya = true;
  domSelectStaff: number | null = null;
  isTooLong4all = false;

  isAdmin = false;

  isFollow = false;
  //----------------------
  //DOM連携用メソッド
  //----------------------
  domDateMonth = "";

  //マップ用
  mapLat = 0;
  mapLng = 0;

  infoData: {
    countDate4Work_1: string;
    countTime4Work_2: string;
    countDate4Work_3: string;
    countTime4Ot_4: string;
    countTime4SpOt_5: string;
    countTime4Inner_6: string;
    countTime4WorkSum_7: string;
  } = {
    countDate4Work_1: "-",
    countTime4Work_2: "-",
    countDate4Work_3: "-",
    countTime4Ot_4: "-",
    countTime4SpOt_5: "-",
    countTime4Inner_6: "-",
    countTime4WorkSum_7: "-",
  };

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalErrorConf = "";
  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    // console.log("1");
    //URLの補正とUIへの反映
    //-----------------------
    this.isNuryokusya = true;
    if (this.$store.state.authType != CONST_AUTHORITY_STAFF_NYURYOKUSYA) {
      this.isNuryokusya = false;
    }

    let id = Cl_common.getParam("id");
    let mo = Cl_common.getParam("mo");
    this.$store.commit("revStore");

    if (id == "" || mo == "") {
      id = this.$store.state.id.toString();
      const date = new Date();
      const year = date.getFullYear();
      let month: string;
      if (21 <= date.getDate()) {
        month = ("00" + (date.getMonth() + 2)).slice(-2);
      } else {
        month = ("00" + (date.getMonth() + 1)).slice(-2);
      }

      mo = year + "-" + month;

      //年跨ぎの補正
      if (month == "13") {
        mo = year + 1 + "-01";
      }

      await this.$router.push({
        path: "/attendanceedit",
        query: { id: id, mo: mo },
      });
    }
    // console.log("2");
    //-----------------------
    await this.getMastersList();

    this.domSelectStaff = Number(id);
    this.domDateMonth = mo;
    // console.log("3");
    // await this.get();//getMastersListをきっかけにして、GETが走るので、呼び出し不要＋重複呼び出しになるので禁止
    // console.log("4");

    window.addEventListener("scroll", this.handleScroll);
  }

  /**
   * スクロールイベント時に呼ばれるメソッド
   * classのオン・オフ、theadの指定を行う。
   */
  handleScroll() {
    let tableHeaderTop = 430;
    if (992 > window.innerWidth) {
      tableHeaderTop = 421;
    }
    if (768 > window.innerWidth) {
      tableHeaderTop = 456;
    }
    if (440 > window.innerWidth) {
      tableHeaderTop = 513;
    }
    if (342 > window.innerWidth) {
      tableHeaderTop = 545;
    }

    if (tableHeaderTop < window.scrollY) {
      this.isFollow = true;
      document.getElementById("tableHeader")!.style.top =
        "" + (window.scrollY - tableHeaderTop) + "px";
    } else {
      this.isFollow = false;
      document.getElementById("tableHeader")!.style.top = "0px";
    }
    console.log(tableHeaderTop);
    console.log(window.scrollY);
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getStaff4Attendance = 1;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);

      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * データを読み込む
   */
  async get() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("GET実行!!");

      //管理者判定
      store.commit("revStore");
      const authType = store.state.authType;
      // Cl_common.logger(authType);
      this.isAdmin = authType == 4; //管理者判定

      // //パラメータ調整
      // //-------
      let id = Cl_common.getParam("id");
      let mo = Cl_common.getParam("mo");
      // this.$store.commit("revStore");

      //準備
      //-------
      let dto = new DtoAttendancesGetReqImple();

      dto.c_attendance_id = Number(id);
      dto.id = Number(id);
      dto.mo = mo;
      dto.getmode = CONST_ATTENDANCE_GETMODE_NORMAL;

      Cl_common.logger("dto");
      Cl_common.logger(dto);

      //API実行
      //-------
      this.delegate = await Cl_attendanceImple.get4AttendanceTable(dto);
      //労働時間関係の計算。
      this.calcData();
      //DOM変換用
      for (
        let index = 0;
        index < this.delegate.items4Attendance.length;
        index++
      ) {
        if (
          this.delegate.items4Attendance[index].c_attendance_remarks != null
        ) {
          this.delegate.items4Attendance[index].cnvRemarks =
            this.delegate.items4Attendance[index].c_attendance_remarks!;
        }
      }

      //出勤時間、退勤時間のチェック
      await this.timeCheck();

      Cl_common.logger(this.delegate);
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
      this.delegate = new DtoAttendancesGetResImple();
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  async upd() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      //DOM変換用
      for (
        let index = 0;
        index < this.delegate.items4Attendance.length;
        index++
      ) {
        this.delegate.items4Attendance[index].c_attendance_remarks =
          this.delegate.items4Attendance[index].cnvRemarks;

        //変更後に、変更箇所を削除した場合、nullではなく空白になるのでnullに置き換え
        //-------
        //出勤時刻
        if (
          this.delegate.items4Attendance[index].c_attendance_time_work == ""
        ) {
          this.delegate.items4Attendance[index].c_attendance_time_work = null;
        }
        //外出時刻
        if (
          this.delegate.items4Attendance[index].c_attendance_time_goout_start ==
          ""
        ) {
          this.delegate.items4Attendance[index].c_attendance_time_goout_start =
            null;
        }
        //戻り時刻
        if (
          this.delegate.items4Attendance[index].c_attendance_time_goout_end ==
          ""
        ) {
          this.delegate.items4Attendance[index].c_attendance_time_goout_end =
            null;
        }
        //退勤時刻
        if (
          this.delegate.items4Attendance[index].c_attendance_time_leaving == ""
        ) {
          this.delegate.items4Attendance[index].c_attendance_time_leaving =
            null;
        }
        //平日時間外
        if (
          this.delegate.items4Attendance[index].c_attendance_ot1_aftertime == ""
        ) {
          this.delegate.items4Attendance[index].c_attendance_ot1_aftertime =
            null;
        }
        //平日深夜
        if (
          this.delegate.items4Attendance[index].c_attendance_ot2_nighttime == ""
        ) {
          this.delegate.items4Attendance[index].c_attendance_ot2_nighttime =
            null;
        }
        //休日時間外
        if (
          this.delegate.items4Attendance[index].c_attendance_ot3_hd_aftertime ==
          ""
        ) {
          this.delegate.items4Attendance[index].c_attendance_ot3_hd_aftertime =
            null;
        }
        //休日深夜
        if (
          this.delegate.items4Attendance[index].c_attendance_ot4_hd_nighttime ==
          ""
        ) {
          this.delegate.items4Attendance[index].c_attendance_ot4_hd_nighttime =
            null;
        }
        //法定時間外
        if (
          this.delegate.items4Attendance[index].c_attendance_ot5_sp_aftertime ==
          ""
        ) {
          this.delegate.items4Attendance[index].c_attendance_ot5_sp_aftertime =
            null;
        }
        //法定深夜
        if (
          this.delegate.items4Attendance[index].c_attendance_ot6_sp_nighttime ==
          ""
        ) {
          this.delegate.items4Attendance[index].c_attendance_ot6_sp_nighttime =
            null;
        }
      }

      let dto = new DtoAttendancesPutReqImple();
      dto.items4Attendance = this.delegate.items4Attendance;

      //API実行
      //-------
      await Cl_attendanceImple.putImple(dto);
      //レスポンスの処理
      //-------
      //モーダルを表示
      this.message4ModalSuccess = "正常に変更されました";
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  async showMap(ev: { lat: string; lng: string }) {
    this.mapLat = Number(ev.lat);
    this.mapLng = Number(ev.lng);

    this.showModal4map();
  }

  /**
   * ファイルをダウンロードする
   */
  async dl() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoFileReportsGetReqImple();
      dto.type = "Attendance";
      let id = Cl_common.getParam("id");
      let mo = Cl_common.getParam("mo");
      dto.attendanceid = Number(id);
      dto.attendancemo = mo;

      //API実行
      //-------
      const res = await Cl_filereport.get(dto);

      if (0 == res.outputqueue_code.length) {
        throw new Error("出力失敗");
      }

      Cl_common.logger("res.outputqueue_code");
      Cl_common.logger(res.outputqueue_code);

      let timeLimit = Date.now() + 15 * 60 * 1000; // 15分
      let now = Date.now();
      let dtoOutputReq = new DtoOutputqueuesGetReqImple();
      dtoOutputReq.c_outputqueue_code = res.outputqueue_code;
      let resOutputReq = new DtoOutputqueuesGetResImple();
      resOutputReq = await Cl_outputqueue.get(dtoOutputReq);
      while (now < timeLimit) {
        Cl_common.logger("wait request");
        await new Promise((resolve) => setTimeout(resolve, 3000)); // 3秒待つ

        now = Date.now();

        resOutputReq = await Cl_outputqueue.get(dtoOutputReq);
        //↓社領修正行
        if (resOutputReq.c_outputqueue_flag_end == 1) {
          break;
        }
      }

      //レスポンス処理
      //-------
      Cl_common.logger("resOutputReq");
      Cl_common.logger(resOutputReq);

      //↓社領修正行
      if (resOutputReq.c_outputqueue_flag_success == 1) {
        location.href = resOutputReq.url;
      } else {
        throw new Error("出力失敗");
      }
      //window.open(res.url, "_blank");
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  // /**
  //  * ファイルをダウンロードする
  //  */
  // async dlold() {
  //   //ボタン無効＋ローダー表示
  //   //-----------------
  //   this.btnDisable = true;
  //   const panel = document.getElementsByClassName("panel-container");
  //   Array.from(panel).map((element: Element) => {
  //     element.classList.add("enable-loader");
  //   });

  //   try {
  //     //処理
  //     //-----------------
  //     Cl_common.logger("処理実行");

  //     //準備
  //     //-------
  //     let dto = new DtoFileMastersGetReqImple();
  //     dto.type = "Attendance";

  //     let id = Cl_common.getParam("id");
  //     let mo = Cl_common.getParam("mo");

  //     dto.attendanceid = Number(id);
  //     dto.attendancemo = mo;

  //     //API実行
  //     //-------
  //     const res = await Cl_filemaster.get(dto);

  //     //レスポンス処理
  //     //-------
  //     location.href = res.url;
  //   } catch (error) {
  //     //モーダルのメッセージを設定
  //     this.message4ModalErrorConf = "エラーが発生しました";
  //     //モーダル表示
  //     const modal: any = this.$refs.Mo_modal_errorConf;
  //     modal.showModal();

  //     Cl_common.logger(error);
  //   } finally {
  //     //ボタン無効解除＋ローダー非表示
  //     //-----------------
  //     this.btnDisable = false;
  //     Array.from(panel).map((element: Element) => {
  //       element.classList.remove("enable-loader");
  //     });
  //   }
  // }

  /**
   * 所定・実務の労働時間等を算出する
   */
  async calcData() {
    let countDate4Work_1Tmp = 0;
    let countTime4SpOt_5Tmp = "00:00";

    //集計
    for (
      let index = 0;
      index < this.delegate.items4Attendance.length;
      index++
    ) {
      // Cl_common.logger(this.delegate.items4Attendance[index].c_attendance_date);
      //-------------------
      if (
        this.delegate.items4Attendance[index].c_attendance_time_work != null
      ) {
        countDate4Work_1Tmp += 1;
      }
      //-------------------
      if (
        this.delegate.items4Attendance[index].c_attendance_ot1_aftertime != null
      ) {
        countTime4SpOt_5Tmp = this.addTimes(
          countTime4SpOt_5Tmp,
          this.delegate.items4Attendance[
            index
          ].c_attendance_ot1_aftertime!.slice(0, -3)
        );
      }
      //-------------------
      if (
        this.delegate.items4Attendance[index].c_attendance_ot2_nighttime != null
      ) {
        // countTime4SpOt_5Tmp = this.addTimes(
        //   countTime4SpOt_5Tmp,
        //   this.delegate.items4Attendance[
        //     index
        //   ].c_attendance_ot2_nighttime!.slice(0, -3)
        // );
      }
      //-------------------
      if (
        this.delegate.items4Attendance[index].c_attendance_ot3_hd_aftertime !=
        null
      ) {
        countTime4SpOt_5Tmp = this.addTimes(
          countTime4SpOt_5Tmp,
          this.delegate.items4Attendance[
            index
          ].c_attendance_ot3_hd_aftertime!.slice(0, -3)
        );
      }
      //-------------------
      if (
        this.delegate.items4Attendance[index].c_attendance_ot4_hd_nighttime !=
        null
      ) {
        // countTime4SpOt_5Tmp = this.addTimes(
        //   countTime4SpOt_5Tmp,
        //   this.delegate.items4Attendance[
        //     index
        //   ].c_attendance_ot4_hd_nighttime!.slice(0, -3)
        // );
      }
      //-------------------
      if (
        this.delegate.items4Attendance[index].c_attendance_ot5_sp_aftertime !=
        null
      ) {
        countTime4SpOt_5Tmp = this.addTimes(
          countTime4SpOt_5Tmp,
          this.delegate.items4Attendance[
            index
          ].c_attendance_ot5_sp_aftertime!.slice(0, -3)
        );
      }
      //-------------------
      if (
        this.delegate.items4Attendance[index].c_attendance_ot6_sp_nighttime !=
        null
      ) {
        // countTime4SpOt_5Tmp = this.addTimes(
        //   countTime4SpOt_5Tmp,
        //   this.delegate.items4Attendance[
        //     index
        //   ].c_attendance_ot6_sp_nighttime!.slice(0, -3)
        // );
      }
    }

    //入力
    this.infoData.countDate4Work_1 = countDate4Work_1Tmp.toString();
    this.infoData.countTime4Work_2 =
      (countDate4Work_1Tmp * 8).toString() + ":00";
    this.infoData.countDate4Work_3 = countDate4Work_1Tmp.toString();
    this.infoData.countTime4Ot_4 = (countDate4Work_1Tmp * 8).toString() + ":00";
    this.infoData.countTime4SpOt_5 = countTime4SpOt_5Tmp;
    this.infoData.countTime4Inner_6 = this.addTimes(
      countTime4SpOt_5Tmp,
      this.infoData.countTime4Ot_4
    );
    try {
      this.infoData.countTime4WorkSum_7 = this.subtractTimes(
        this.infoData.countTime4Inner_6,
        this.infoData.countTime4Work_2
      );
    } catch (error) {
      this.infoData.countTime4WorkSum_7 = "00:00";
    }

    //   infoData: {
    //   countDate4Work_1: string;
    //   countTime4Work_2: string;
    //   countDate4Work_3: string;
    //   countTime4Ot_4: string;
    //   countTime4SpOt_5: string;
    //   countTime4Inner_6: string;
    //   countTime4WorkSum_7: string;
    // } = {
    //   countDate4Work_1: "-",
    //   countTime4Work_2: "-",
    //   countDate4Work_3: "-",
    //   countTime4Ot_4: "-",
    //   countTime4SpOt_5: "-",
    //   countTime4Inner_6: "-",
    //   countTime4WorkSum_7: "-",
    // };
  }
  isValidTimeFormat(time: string): boolean {
    const regex = /^\d+:[0-5][0-9]$/;
    return regex.test(time);
  }

  parseTime(timeString: string): { hours: number; minutes: number } {
    const [hours, minutes] = timeString.split(":").map(Number);
    return { hours, minutes };
  }
  /**
   * HH:mm形式の文字列を時間と認識して足し算を行う
   * @param time1
   * @param time2
   */
  addTimes(time1: string, time2: string): string {
    if (!this.isValidTimeFormat(time1) || !this.isValidTimeFormat(time2)) {
      throw new Error("Invalid time format. Expected format is HH:mm");
    }

    const parsedTime1 = this.parseTime(time1);
    const parsedTime2 = this.parseTime(time2);

    let minutes = parsedTime1.minutes + parsedTime2.minutes;
    let hours = parsedTime1.hours + parsedTime2.hours;

    if (minutes >= 60) {
      hours += Math.floor(minutes / 60);
      minutes = minutes % 60;
    }

    // 結果を HH:mm 形式に整形
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}`;
  }
  /**
   * HH:mm形式の文字列を時間と認識して引き算を行う
   * @param time1
   * @param time2
   */
  subtractTimes(time1: string, time2: string): string {
    if (!this.isValidTimeFormat(time1) || !this.isValidTimeFormat(time2)) {
      throw new Error("Invalid time format. Expected format is HH:mm");
    }

    const parsedTime1 = this.parseTime(time1);
    const parsedTime2 = this.parseTime(time2);

    let minutes = parsedTime1.minutes - parsedTime2.minutes;
    let hours = parsedTime1.hours - parsedTime2.hours;

    if (minutes < 0) {
      hours -= 1;
      minutes = 60 + minutes;
    }

    if (hours < 0) {
      throw new Error(
        "Time underflow error. The first time should be greater than the second time"
      );
    }

    // 結果を HH:mm 形式に整形
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}`;
  }

  /**
   * 月表示をデクリする
   */
  async prevMonth() {
    let date = new Date(this.domDateMonth);
    date.setMonth(date.getMonth() - 1);
    this.domDateMonth = this.formatDate(date);

    // //URLに反映してget呼び出し
    // let id = Cl_common.getParam("id");
    // await this.$router.push({
    //   path: "/attendanceedit",
    //   query: { id: id, mo: this.domDateMonth },
    // });

    // await this.get();
  }
  /**
   * 月表示をインクリする
   */
  async nextMonth() {
    let date = new Date(this.domDateMonth);
    date.setMonth(date.getMonth() + 1);
    this.domDateMonth = this.formatDate(date);

    // //URLに反映してget呼び出し
    // let id = Cl_common.getParam("id");
    // await this.$router.push({
    //   path: "/attendanceedit",
    //   query: { id: id, mo: this.domDateMonth },
    // });

    // await this.get();
  }

  /**
   * 月表示の変化を検知
   * watch用
   */
  @Watch("domDateMonth")
  async cngMonth() {
    //URLに反映してget呼び出し
    let id = Cl_common.getParam("id");
    await this.$router.push({
      path: "/attendanceedit",
      query: { id: id, mo: this.domDateMonth },
    });

    await this.get();
  }

  /**
   * セレクトのの変化を検知
   * watch用
   */
  @Watch("domSelectStaff")
  async cngSelect(newVal: number | null, oldVal: number | null) {
    // console.log("kita!");
    // console.log(this.domSelectStaff);
    // console.log("変化前の値: " + oldVal + ", 変化後の値: " + newVal);
    if (oldVal == null) {
      return;
    }

    //URLに反映してget呼び出し
    let mo = Cl_common.getParam("mo");
    await this.$router.push({
      path: "/attendanceedit",
      query: { id: this.domSelectStaff, mo: mo },
    });
    await this.get();
  }

  /**
   * 出退勤の時間をチェックして警告を出す
   */
  async timeCheck() {
    this.isTooLong4all = false;

    for (
      let index = 1;
      index < this.delegate.items4Attendance.length;
      index++
    ) {
      //日付が連続する場合にチェックを入れる

      const previousRecord = this.delegate.items4Attendance[index - 1];
      const currentRecord = this.delegate.items4Attendance[index];

      if (
        previousRecord.c_attendance_date != null &&
        currentRecord.c_attendance_date != null
      ) {
        // 前のデータが前日のデータであるかをチェック
        const previousDate = new Date(previousRecord.c_attendance_date!);
        const currentDate = new Date(currentRecord.c_attendance_date!);

        const previousDay = new Date(currentDate);
        previousDay.setDate(previousDay.getDate() - 1);

        if (previousDate.getDate() !== previousDay.getDate()) {
          continue;
        }
      }

      if (
        previousRecord.c_attendance_time_leaving != null &&
        currentRecord.c_attendance_time_work != null
      ) {
        // startTimeとendTimeを日付オブジェクトに変換
        const startTimeParts = currentRecord.c_attendance_time_work.split(":");
        const endTimeParts =
          previousRecord.c_attendance_time_leaving.split(":");
        const previousEndTime = new Date(previousRecord.c_attendance_date!);

        previousEndTime.setHours(
          parseInt(endTimeParts[0], 10),
          parseInt(endTimeParts[1], 10),
          0
        ); // endTimeを日付オブジェクトに変換
        const currentStartTime = new Date(currentRecord.c_attendance_date!);
        currentStartTime.setHours(
          parseInt(startTimeParts[0], 10),
          parseInt(startTimeParts[1], 10),
          0
        ); // startTimeを日付オブジェクトに変換

        const previousEndHour = parseInt(endTimeParts[0], 10);

        if (previousEndHour >= 0 && previousEndHour < 5) {
          // 日付を翌日に変更
          previousEndTime.setDate(previousEndTime.getDate() + 1);
        }

        // 出勤時間と退勤時間の差が10時間以内かをチェック
        if (
          currentStartTime.getTime() - previousEndTime.getTime() <=
          CONST_ATTENDANCE_TIME_DIFFERENCE * 60 * 60 * 1000
        ) {
          // 10時間以内の場合の処理
          this.delegate.items4Attendance[index].isTooLong = true;
          this.isTooLong4all = true;
        } else {
          // 10時間以上の場合の処理
        }
      }
    }
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
  async showModal4map(): Promise<void> {
    const modal: any = this.$refs.Or_map;
    modal.showModal();
  }
  async closeModal4map(): Promise<void> {
    const modal: any = this.$refs.Or_map;
    modal.closeModal();
  }
  //----------------------
  //その他のメソッド（画面独自のもの等）
  //----------------------
  /**
   * 日付型の変数をyyyy-MMフォーマットの文字列に変換する
   * @param date
   */
  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ("00" + (date.getMonth() + 1)).slice(-2);

    return `${year}-${month}`;
  }
}
