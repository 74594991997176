import { EntityStaff } from "../entity/EntityStaff";
import { EntityStaff4analy } from "../entity/EntityStaff4analy";
import { EntityClient } from "../entity/EntityClient";
import { EntitySupplier } from "../entity/EntitySupplier";
import { EntityBankImple } from "../entity/EntityBankImple";
import { EntityHoliday } from "../entity/EntityHoliday";
import { EntityAggClientImple } from "../entity/EntityAggClientImple";
import { EntityAgg4client4rank } from "../entity/EntityAgg4client4rank";
import { EntityAgg4pro } from "../entity/EntityAgg4pro";

export class DtoAggregatesGetRes {
  // バッジ 0用
  //-------------------
  public Badge_0_1_sales: number = 0;
  public Badge_0_2_profit: number = 0;
  public Badge_0_3_gale2salseRatio: number = 0;
  // public Badge_0_4_usageDateRatio: number = 0;
  public Badge_0_4_gale2profitRatio: number = 0;
  public Badge_0_5_profitRatio: number = 0;
  public Badge_0_6_earnings: number = 0;
  public Badge_0_7_gross: number = 0;
  public Badge_0_targetDate: string = "";
  // 全体サマリ 100用
  //-------------------
  public summary_graph_100_chartData: any = {};
  // 全体サマリ 200用
  //-------------------
  public summary_list_200_listData: EntityAggClientImple[] = [];
  // 月別売上先[受注]売上・仕入額 300用
  //-------------------
  public client_graph_300_chartData_now: any = {};
  public client_graph_300_chartData_now4Suupy: any = {};
  public client_graph_300_chartData_now4Profit: any = {};
  public client_graph_300_chartData_onePrev: any = {};
  public client_graph_300_chartData_onePrev4Suupy: any = {};
  public client_graph_300_chartData_onePrev4Profit: any = {};
  public client_graph_300_chartData_twoPrev: any = {};
  public client_graph_300_max_x: number = 100000000;
  // 月別売上先（売上額） 400用
  //-------------------
  public client_graph_400_chartData: any = {};
  // 月別売上先（売上額） 500用
  //-------------------
  public client_list_500_listData: EntityAgg4client4rank[] = [];
  // 月別売上先（売上額） 600用
  //-------------------
  // public summary_list_600_listData: EntityAggClientImple[] = [];

  //担当営業一覧1900用
  //-------------------
  public staff_list_1900_listData: EntityStaff4analy[] = [];

  //担当営業情報
  //-------------------
  public dataStaff: EntityStaff = new EntityStaff();

  //担当営業詳細　バッジ2000用
  //-------------------
  /**
   * 受注売上額
   */
  public badge_20001_sales_order: number = 0;
  /**
   * 受注仕入額
   */
  public badge_20002_supply_order: number = 0;
  /**
   * 受注利益額
   */
  public badge_20003_profit_order: number = 0;
  /**
   * 受注利益率
   */
  public badge_20004_ratio_profit_order: number = 0;
  /**
   * 確定売上額
   */
  public badge_20005_sales: number = 0;
  /**
   * 確定仕入額
   */
  public badge_20006_supply: number = 0;
  /**
   * 確定利益額
   */
  public badge_20007_profit: number = 0;
  /**
   * 確定利益率
   */
  public badge_20008_ratio_profit: number = 0;

  // 営業担当全体サマリ 2100用
  //-------------------
  public summary_graph_2100_chartData: any = {};

  // 売上先別売上額グラフ 22001用
  //-------------------
  public client_graph_22001_chartData: any = {};
  // 売上先別売上額グラフ 22002用
  //-------------------
  public client_graph_22002_chartData: any = {};
  // 売上先別売上額グラフ 23001用
  //-------------------
  public client_graph_23001_chartData: any = {};

  // 案件一覧 2400用
  //-------------------
  public project_list_2400: EntityAgg4pro[] = [];

  public async setClientName2Summary_list_200_listData(
    cnvClientId2Name: Map<string, string>
  ) {
    for (
      let index = 0;
      index < this.summary_list_200_listData.length;
      index++
    ) {
      if (
        cnvClientId2Name.has(
          this.summary_list_200_listData[index].c_client_id!.toString()
        )
      ) {
        this.summary_list_200_listData[index].c_client_name =
          cnvClientId2Name.get(
            this.summary_list_200_listData[index].c_client_id!.toString()
          )!;
      } else {
        this.summary_list_200_listData[index].c_client_name = "";
      }
    }
  }
}
