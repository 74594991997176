import { commonRegx } from "xcommon-node/src/common/commonRegx";
/**
 * 経費精算書の一覧用のエンティティ
 * Jsonにして扱う
 */
export class EntityRequestExpensesList {
  public c_expenses_rownum: number | null = null;
  public c_expenses_date: Date | null = null;
  public c_expenses_type: number | null = null;
  public c_expenses_payment_destination: string | null = null;
  public c_expenses_purpose: string | null = null;
  public c_expenses_count: number | null = null;
  public c_expenses_price: number | null = null;
  /**
   * 1:不要;2:必要;3:不明;
   */
  public c_expenses_invoice: number | null = null;

  //DOM連携用
  //-------------------
  public cnvExpensesDate: string | undefined = "";
  public cnvPrice: string = "";

  //行選択用
  public isSelect: boolean = false;

  /**
   * データからDOM連携用の変数にデータを入力する
   */
  public async cnvData2dom() {
    //日付
    //-------------
    if (this.c_expenses_date != null) {
      this.cnvExpensesDate = commonRegx.maches4GroupName(
        this.c_expenses_date.toString(),
        "(?<gn>^\\d+[-]\\d+)[-]",
        commonRegx._stdOption
      );
    }

    //金額
    //-------------
    if (this.c_expenses_price == null) {
      // this.cnvPrice = "0";
    } else {
      this.cnvPrice = this.c_expenses_price.toString();
      //カンマ補完
      while (
        this.cnvPrice !=
        (this.cnvPrice = this.cnvPrice!.replace(/^(-?\d+)(\d{3})/, "$1,$2"))
      );
    }
  }
  /**
   * DOM連携用の変数からデータ用の変数に入力する
   */
  public async cnvDom2data() {
    //日付
    //-------------
    if (this.cnvExpensesDate != undefined) {
      this.c_expenses_date = new Date(this.cnvExpensesDate);
    }

    //金額
    //-------------
    if (this.cnvPrice == null || this.cnvPrice == undefined) {
      // this.c_expenses_price = 0;
    } else {
      this.c_expenses_price = Number(this.cnvPrice.replace(/,/g, ""));
    }
  }
}
