//-------------------------------
//定数。
//-------------------------------
//記入例
/**
 * ソート方法。新着(new) / ランキング(rank) / 人気(pop) // マイリストのnameとrelease
 */
// export const CONST_CODE_SORT: string[] = ["new", "rank", "pop", "name", "release"];

export const CONST_ITEM: string[] = ["", "新規", "継続"];

export const CONST_SUBJECTS: string[] = [
  "",
  "長期看板",
  "短期看板",
  "リムジンバス",
  "都営交通",
  "交通広告",
  "ビジョン",
  "経費",
  "その他",
];

export const CONST_BREAKDOWNS: string[] = [
  "",
  "媒体料",
  "プリント",
  "施工",
  "製作施工",
  "工事(資産)",
  "保険・申請",
  "撤去前受",
  "その他",
];

export const CONST_BRANCHS: string[] = ["", "東京"];

export const CONST_BANK_NAME: string[] = [
  "",
  "みずほ銀行",
  "りそな銀行",
  "三井住友銀行",
  "三菱UFJ銀行",
];

export const CONST_BANK_TYPE: string[] = ["", "当座預金", "普通預金"];

//-------------------------------
//仕入明細の支払確認チェックが入力可能な社員
//-------------------------------
//葛谷(4)、山本(27)、阿児(43)、岩崎(28)
export const CONST_AUTH_PAYCHECK = [4, 27, 43, 28];

//-------------------------------
//GOOGLEMAP。
//-------------------------------
export const CONST_APIKEY_GOOGLEMAP = "AIzaSyAvHe5WlWFRdw6G171wDb71fZ6-rfWzobw"; //XECINアカウントで契約

//-------------------------------
//URL関係。
//-------------------------------
export const CONST_HOST_PROD = "new.sys.mediadept.co.jp"; //本番環境のホスト

//-------------------------------
//本番のlambda名。
//-------------------------------
export const CONST_LAMBDA_NAME_PROD = "lambda_mediadept_prod";

//-------------------------------
//ログ関係。
//-------------------------------

/**
 * 開発用環境時に、ログを処理するlambda名
 */
export const CONST_LAMBDA_NAME_DEVLOGING = "lambda_mediadept_devset2";

/**
 * 本番用のログLambdaの名称
 */
export const CONST_LOG_LAMBDA_NAME_PROD = "lambda_mediadept_prod_log";
/**
 * 開発用のログLambdaの名称
 */
export const CONST_LOG_LAMBDA_NAME_DEV = "lambda_mediadept_dev_log";

/**
 * 環境毎に使用できるログ用Lambdaの名前を返す。
 * 単純にisProdフラグでの管理になっており、
 * @returns
 */
export function getLogLambdaName(): string {
  if (process.env.isProd != undefined && process.env.isProd == "1") {
    //本番
    return CONST_LOG_LAMBDA_NAME_PROD;
  } else {
    //開発
    return CONST_LOG_LAMBDA_NAME_DEV;
  }
}

//-------------------------
//S3関係
//-------------------------
/**
 * 帳票Template用バケット名
 */
export const CONST_BUCKET_NAME_TEMPLATES: string = "mediadept-report-templates";
/**
 * 帳票出力用のバケット名
 */
export const CONST_BUCKET_NAME_OUTPUT: string = "mediadept-report-output";
/**
 * DB一括入力CSV用のバケット名
 */
export const CONST_BUCKET_NAME_DB_OPERATOR: string =
  "mediadept-dboperator-base";
/**
 * DBファイル保存用バケット
 */
export const CONST_BUCKET_NAME_FILES_PROD: string = "mediadept-files-prod";
/**
 * DBファイル保存用バケット
 */
export const CONST_BUCKET_NAME_FILES_DEV: string = "mediadept-files-dev";
/**
 * 環境毎に使用できるDBファイル保存用バケットを返す。
 * Lambdaの環境変数で判断するAPI用。
 * @returns
 */
export function getBucketNameFiles4API(): string {
  if (process.env.isProd != undefined && process.env.isProd == "1") {
    //本番
    return CONST_BUCKET_NAME_FILES_PROD;
  } else {
    //開発
    return CONST_BUCKET_NAME_FILES_DEV;
  }
}
//-------------------------
//その他
//-------------------------
/**
 * 本番環境か開発環境かを返す
 * @returns
 */
export function isProdEnv(): boolean {
  if (window.location.host == CONST_HOST_PROD) {
    // 本番環境
    return true;
  } else {
    // 開発環境
    return false;
  }
}

//-------------------------
//メール送信関係
//-------------------------
/**
 *  メールの送信処理をルーティングする為の文字列
 * 保険申請：insurance
 */
export const CONST_MAILTYPE_INSURANCE: string = "insurance";
/**
 * 管理者への通知
 * 管理者通知：adminAlert
 */
export const CONST_MAILTYPE_ADMINALERT: string = "adminAlert";

//-------------------------------
//Cognito関係。
//-------------------------------
/**
 * CognitoのユーザープールID。
 */
export const CONST_COGNITO_USERPOOL_ID_DEV = "ap-northeast-1_TKj0So2H9"; // 開発環境
export const CONST_COGNITO_USERPOOL_ID_PROD = "ap-northeast-1_NB3akzAoP"; // 本番環境

/**
 * CognitoのアプリクライアントID。
 */
export const CONST_COGNITO_APPCLIENT_ID_DEV = "65nd3npmr6s0180k0b2ph58l8d"; // 開発環境
export const CONST_COGNITO_APPCLIENT_ID_PROD = "29o3uo7indhg7ehk0o3s982io3"; // 本番環境

/**
 * 環境毎に使用できるユーザープールIDを返す。
 * Lambdaの環境変数で判断するAPI用。
 * @returns
 */
export function getCognitoUserPoolId4API(): string {
  if (process.env.isProd != undefined && process.env.isProd == "1") {
    //本番
    return CONST_COGNITO_USERPOOL_ID_PROD;
  } else {
    //開発
    return CONST_COGNITO_USERPOOL_ID_DEV;
  }
}
/**
 * 環境毎に使用できるクライアントIDを返す。
 * Lambdaの環境変数で判断するAPI用。
 * @returns
 */
export function getCognitoAppClientId4API(): string {
  if (process.env.isProd != undefined && process.env.isProd == "1") {
    //本異
    return CONST_COGNITO_APPCLIENT_ID_PROD;
  } else {
    //開癰
    return CONST_COGNITO_APPCLIENT_ID_DEV;
  }
}
/**
 * 環境毎に使用できるユーザープールIDを返す。
 * URLで判断するフロント用。
 * @returns
 */
export function getCognitoUserPoolId4Front(): string {
  if (window.location.host == CONST_HOST_PROD) {
    // 本番環境
    return CONST_COGNITO_USERPOOL_ID_PROD;
  } else {
    // 開発環境
    return CONST_COGNITO_USERPOOL_ID_DEV;
  }
}
/**
 * 環境毎に使用できるクライアントIDを返す。
 * URLで判断するフロント用。
 * @returns
 */
export function getCognitoAppClientId4Front(): string {
  if (window.location.host == CONST_HOST_PROD) {
    //本異
    return CONST_COGNITO_APPCLIENT_ID_PROD;
  } else {
    //開癰
    return CONST_COGNITO_APPCLIENT_ID_DEV;
  }
}

//-------------------------
//t_settingのkey
//-------------------------
/**
 * 発行している請求書Noの最大値
 */
export const CONST_SETTING_KEY_CLAMNO: string = "clamno";

//-------------------------
//集計関係
//-------------------------

export const CONST_AGGTYPE_JYUTYU: number = 1;
export const CONST_AGGTYPE_KAKUTEI: number = 2;
export const CONST_AGGTYPE_NUM_1: string = "受注額";
export const CONST_AGGTYPE_NUM_2: string = "確定額";

export const CONST_AGGTYPE = [
  { label: "受注額", code: 1 },
  { label: "確定額", code: 2 },
];

//-------------------------
//商品・受注・売上・仕入関係
//-------------------------
export const CONST_OP_TAXRATE = [
  { label: "非課税", code: 0 },
  { label: "5%", code: 1 },
  { label: "8%", code: 2 },
  { label: "10%", code: 3 },
  { label: "内税", code: 4 },
];

export const CONST_OP_PAYMENTSITE = [
  { label: "都度払い（要確認）", code: 1 },
  { label: "当月末", code: 2 },
  { label: "翌末", code: 3 },
  { label: "翌翌末", code: 4 },
  { label: "翌々10日", code: 5 },
];
export const CONST_OP_CLAIM_LANGUAGE = [
  { label: "日本語", code: 1 },
  { label: "英語", code: 2 },
];

export const CONST_OP_ALERT_CONTRACT = [
  { label: "1ヶ月前", code: 1 },
  { label: "2ヶ月前", code: 2 },
  { label: "3ヶ月前", code: 3 },
  { label: "4ヶ月前", code: 4 },
];
export const CONST_OP_TERM_ITEM = [
  { label: "長期", code: 1 },
  { label: "短期", code: 2 },
];
export const CONST_OP_SUBJECT = [
  { label: "新規", code: 1 },
  { label: "継続", code: 2 },
];
export const CONST_OP_BILLING_TYPE = [
  { label: "一括", code: 1 },
  { label: "分割", code: 2 },
];

//売り上げの有無
//---------------------------------
export const CONST_OP_SALES_FLAG = [
  { label: "売上あり", code: 1 },
  { label: "売上なし", code: 2 },
];

export const CONST_OP_SALES_FLAG_URIAGE_ARI: number = 1;
export const CONST_OP_SALES_FLAG_URIAGE_NASHI: number = 2;

//案件種類
//---------------------------------
export const CONST_OP_PROJECT_TYPE = [
  { label: "不使用", code: 1 },
  { label: "売上", code: 2 },
  { label: "仕掛", code: 3 },
];

export const CONST_PROJECT_TYPE_HUSIYOU: number = 1;
export const CONST_PROJECT_TYPE_URIAGE: number = 2;
export const CONST_PROJECT_TYPE_SIKAKAEI: number = 3;
export const CONST_PROJECT_TYPE_NUM_1: string = "不使用";
export const CONST_PROJECT_TYPE_NUM_2: string = "売上";
export const CONST_PROJECT_TYPE_NUM_3: string = "仕掛";

export const CONST_OP_SUPPLY_TYPE = [
  { label: "媒体・製作施工", code: 1 },
  { label: "媒体・施工", code: 2 },
  { label: "製作施工", code: 3 },
  { label: "媒体費", code: 4 },
  { label: "施工費", code: 5 },
  { label: "取付費", code: 6 },
  { label: "撤去費", code: 7 },
  { label: "撤去（前受）", code: 8 },
  { label: "プリント", code: 9 },
  { label: "製作費", code: 10 },
  { label: "工事（資産）", code: 11 },
  { label: "電気代", code: 12 },
  { label: "申請費", code: 13 },
  { label: "その他", code: 14 },
];
export const CONST_OP_SPECIFICATION_STATUS = [
  { label: "外照式", code: 1 },
  { label: "内照式", code: 2 },
  { label: "照明なし", code: 3 },
  { label: "自発光（LEDなど）", code: 4 },
  { label: "ターポリン", code: 5 },
  { label: "FFシート", code: 6 },
  { label: "粘着シート", code: 7 },
  { label: "懸垂幕", code: 8 },
  { label: "ポスター", code: 9 },
  { label: "屋上", code: 10 },
  { label: "壁面", code: 11 },
  { label: "自立", code: 12 },
  { label: "直貼り", code: 13 },
  { label: "その他", code: 14 },
];

export const CONST_OP_OWNER = [
  { label: "自社", code: 1 },
  { label: "他社", code: 2 },
  { label: "都営", code: 3 },
  { label: "リムジン", code: 4 },
  { label: "その他", code: 5 },
];

export const CONST_OP_TERM_TYPE = [
  { label: "日間", code: 1 },
  { label: "週間", code: 2 },
  { label: "ヵ月", code: 3 },
  { label: "年間", code: 4 },
];
export const CONST_OP_INSURANCE = [
  { label: "必要", code: 1 },
  { label: "不要", code: 2 },
];
export const CONST_OP_AREA_TYPE = [
  { label: "シティ", code: 1 },
  { label: "ストリート", code: 2 },
  { label: "ロード", code: 3 },
  { label: "高速", code: 4 },
  { label: "駅", code: 5 },
  { label: "車内", code: 6 },
  { label: "車体", code: 7 },
];
export const CONST_OP_CHKCLAIM = [
  { label: "未確認", code: 0 },
  { label: "確認済", code: 1 },
];

//商品種別
//-------

export const CONST_OP_ITEMTYPE = [
  { label: "ビルボード", code: 1 },
  { label: "ポスタージャック", code: 2 },
  { label: "商業施設", code: 3 },
  { label: "デジタルビルボード", code: 4 },
  { label: "交通", code: 5 },
  { label: "その他", code: 6 },
];

export const CONST_ITEMTYPE_BIRUBODO: number = 1;
export const CONST_ITEMTYPE_POSTAJAKU: number = 2;
export const CONST_ITEMTYPE_SYOUGYOUSISETU: number = 3;
export const CONST_ITEMTYPE_DEJITARUBIRUBODO: number = 4;
export const CONST_ITEMTYPE_KOUTU: number = 5;
export const CONST_ITEMTYPE_SONOTA: number = 6;
export const CONST_ITEMTYPE_NUM_1: string = "ビルボード";
export const CONST_ITEMTYPE_NUM_2: string = "ポスタージャック";
export const CONST_ITEMTYPE_NUM_3: string = "商業施設";
export const CONST_ITEMTYPE_NUM_4: string = "デジタルビルボード";
export const CONST_ITEMTYPE_NUM_5: string = "交通";
export const CONST_ITEMTYPE_NUM_6: string = "その他";

//-------------------------
//社員・権限関係
//1:入力者;2:集計者;3:権限者;4:管理者;
//-------------------------
export const CONST_AUTHORITY_STAFF_NYURYOKUSYA: number = 1;
export const CONST_AUTHORITY_STAFF_SYUKEISYA: number = 2;
export const CONST_AUTHORITY_STAFF_KENGENSYA: number = 3;
export const CONST_AUTHORITY_STAFF_KANRISYA: number = 4;
export const CONST_AUTHORITY_STAFF_NUM_1: string = "入力者";
export const CONST_AUTHORITY_STAFF_NUM_2: string = "集計者";
export const CONST_AUTHORITY_STAFF_NUM_3: string = "権限者";
export const CONST_AUTHORITY_STAFF_NUM_4: string = "管理者";

//-------------------------
//請求書関係
//-------------------------

export const CONST_CLAIM_EXPTYPE_1_SYAIN: number = 1;
export const CONST_CLAIM_EXPTYPE_2_LOGO: number = 2;
export const CONST_CLAIM_EXPTYPE_3_NONE: number = 3;

export const CONST_OP_CLAIM_STATUS = [
  { label: "未承認未出力", code: 0 },
  { label: "承認依頼済み未承認", code: 1 },
  { label: "承認済み未出力", code: 2 },
  { label: "出力済", code: 3 },
  { label: "差戻済み未承認", code: 9 },
];

export const CONST_CLAIMSTATUS_MISYOUNINMISYUTURYOKU: number = 0;
export const CONST_CLAIMSTATUS_SYOUNINIRAIZUMIMISYOUNIN: number = 1;
export const CONST_CLAIMSTATUS_SASIMODOSIZUMIMISYOUNIN: number = 9;
export const CONST_CLAIMSTATUS_SYOUNINZUMIMISYUTURYOKU: number = 2;
export const CONST_CLAIMSTATUS_SAKUSEIZUMI: number = 3;
export const CONST_CLAIMSTATUS_NUM_0: string = " 未承認未出力";
export const CONST_CLAIMSTATUS_NUM_1: string = " 承認依頼済み未承認";
export const CONST_CLAIMSTATUS_NUM_9: string = "差戻済み未承認";
export const CONST_CLAIMSTATUS_NUM_2: string = "承認済み未出力";
export const CONST_CLAIMSTATUS_NUM_3: string = "出力済";

//-------------------

export const CONST_OP_CLAIM_ROUDING = [
  { label: "四捨五入", code: 0 },
  { label: "切り上げ", code: 1 },
  { label: "切り捨て", code: 2 },
];

export const CONST_CLAIM_ROUDING_SISYAGONYU: number = 0;
export const CONST_CLAIM_ROUDING_KIRIAGE: number = 1;
export const CONST_CLAIM_ROUDING_KIRISUTE: number = 2;
export const CONST_CLAIM_ROUDING_NUM_0: string = "四捨五入";
export const CONST_CLAIM_ROUDING_NUM_1: string = "切り上げ";
export const CONST_CLAIM_ROUDING_NUM_2: string = "切り捨て";

//-------------------------
//申請書関係
//-------------------------
export const CONST_REQUEST_TYPE_KARIBARAI: number = 1;
export const CONST_REQUEST_TYPE_ZANGYO: number = 2;
export const CONST_REQUEST_TYPE_REMOTEWORK: number = 3;
export const CONST_REQUEST_TYPE_SHUTCHO: number = 4;
export const CONST_REQUEST_TYPE_SETTAI: number = 5;
export const CONST_REQUEST_TYPE_SHAYOSHASHIYO: number = 6;
export const CONST_REQUEST_TYPE_KEIHISEISAN: number = 7;
export const CONST_REQUEST_TYPE_KYUKATODOKE: number = 8;
export const CONST_REQUEST_TYPE_RINGISHO: number = 9;
export const CONST_REQUEST_TYPE_KYUSHOKUNEGAI: number = 10;
export const CONST_REQUEST_TYPE_KYUYOSHOYOKOZATOROKU: number = 11;
export const CONST_REQUEST_TYPE_JUSHOHENKOTSUKINTEATE: number = 12;
export const CONST_REQUEST_TYPE_KEIHIKOZATOROKU: number = 13;
export const CONST_REQUEST_TYPE_NUM_1: string = "仮払申請書";
export const CONST_REQUEST_TYPE_NUM_2: string = "時間外残業申請書";
export const CONST_REQUEST_TYPE_NUM_3: string = "リモートワーク申請書";
export const CONST_REQUEST_TYPE_NUM_4: string = "出張申請書";
export const CONST_REQUEST_TYPE_NUM_5: string = "接待申請書";
export const CONST_REQUEST_TYPE_NUM_6: string = "社用車使用許可申請書";
export const CONST_REQUEST_TYPE_NUM_7: string = "経費精算書";
export const CONST_REQUEST_TYPE_NUM_8: string = "休暇届";
export const CONST_REQUEST_TYPE_NUM_9: string = "稟議書";
export const CONST_REQUEST_TYPE_NUM_10: string = "休職願";
export const CONST_REQUEST_TYPE_NUM_11: string = "給与賞与振込口座登録依頼書";
export const CONST_REQUEST_TYPE_NUM_12: string = "住所変更及び通勤手当申請書";
export const CONST_REQUEST_TYPE_NUM_13: string = "経費振込口座登録依頼書";

export const CONST_REQUEST_STATUS_MISINSEI: number = 0;
export const CONST_REQUEST_STATUS_SINSEITYU: number = 1;
export const CONST_REQUEST_STATUS_SASIMODOSI: number = 2;
export const CONST_REQUEST_STATUS_SYOUNINZUMI: number = 3;
export const CONST_REQUEST_STATUS_TORISAGE: number = 4;

export const CONST_REQUEST_STATUS_NUM_0: string = "未申請";
export const CONST_REQUEST_STATUS_NUM_1: string = "申請中";
export const CONST_REQUEST_STATUS_NUM_2: string = "差戻";
export const CONST_REQUEST_STATUS_NUM_3: string = "承認済";
export const CONST_REQUEST_STATUS_NUM_4: string = "取下";

export const CONST_APPROVAL_STATUS_SYOUNIN: number = 1;
export const CONST_APPROVAL_STATUS_SASIMODOSI: number = 2;
export const CONST_APPROVAL_STATUS_JYOUISYOUNIN: number = 3;

export const CONST_APPROVAL_STATUS_NUM_1: string = "承認";
export const CONST_APPROVAL_STATUS_NUM_2: string = "差戻";
export const CONST_APPROVAL_STATUS_NUM_3: string = "上位承認";

/**
 * 申請書タイプの番号から、名称文字列に変換
 * @param requestType
 * @returns
 */
export function cnvRequestTypeNum2Name(requestType: number): string {
  switch (requestType) {
    case CONST_REQUEST_TYPE_KARIBARAI:
      return CONST_REQUEST_TYPE_NUM_1;
    case CONST_REQUEST_TYPE_ZANGYO:
      return CONST_REQUEST_TYPE_NUM_2;
    case CONST_REQUEST_TYPE_REMOTEWORK:
      return CONST_REQUEST_TYPE_NUM_3;
    case CONST_REQUEST_TYPE_SHUTCHO:
      return CONST_REQUEST_TYPE_NUM_4;
    case CONST_REQUEST_TYPE_SETTAI:
      return CONST_REQUEST_TYPE_NUM_5;
    case CONST_REQUEST_TYPE_SHAYOSHASHIYO:
      return CONST_REQUEST_TYPE_NUM_6;
    case CONST_REQUEST_TYPE_KEIHISEISAN:
      return CONST_REQUEST_TYPE_NUM_7;
    case CONST_REQUEST_TYPE_KYUKATODOKE:
      return CONST_REQUEST_TYPE_NUM_8;
    case CONST_REQUEST_TYPE_RINGISHO:
      return CONST_REQUEST_TYPE_NUM_9;
    case CONST_REQUEST_TYPE_KYUSHOKUNEGAI:
      return CONST_REQUEST_TYPE_NUM_10;
    case CONST_REQUEST_TYPE_KYUYOSHOYOKOZATOROKU:
      return CONST_REQUEST_TYPE_NUM_11;
    case CONST_REQUEST_TYPE_JUSHOHENKOTSUKINTEATE:
      return CONST_REQUEST_TYPE_NUM_12;
    case CONST_REQUEST_TYPE_KEIHIKOZATOROKU:
      return CONST_REQUEST_TYPE_NUM_13;
    default:
      throw new Error("不正なrequestType");
  }
}
/**
 * 申請書タイプの名称文字列から、番号に変換
 * @param requestType
 * @returns
 */
export function cnvRequestTypeName2Num(requestType: string): number {
  switch (requestType) {
    case CONST_REQUEST_TYPE_NUM_1:
      return CONST_REQUEST_TYPE_KARIBARAI;
    case CONST_REQUEST_TYPE_NUM_2:
      return CONST_REQUEST_TYPE_ZANGYO;
    case CONST_REQUEST_TYPE_NUM_3:
      return CONST_REQUEST_TYPE_REMOTEWORK;
    case CONST_REQUEST_TYPE_NUM_4:
      return CONST_REQUEST_TYPE_SHUTCHO;
    case CONST_REQUEST_TYPE_NUM_5:
      return CONST_REQUEST_TYPE_SETTAI;
    case CONST_REQUEST_TYPE_NUM_6:
      return CONST_REQUEST_TYPE_SHAYOSHASHIYO;
    case CONST_REQUEST_TYPE_NUM_7:
      return CONST_REQUEST_TYPE_KEIHISEISAN;
    case CONST_REQUEST_TYPE_NUM_8:
      return CONST_REQUEST_TYPE_KYUKATODOKE;
    case CONST_REQUEST_TYPE_NUM_9:
      return CONST_REQUEST_TYPE_RINGISHO;
    case CONST_REQUEST_TYPE_NUM_10:
      return CONST_REQUEST_TYPE_KYUSHOKUNEGAI;
    case CONST_REQUEST_TYPE_NUM_11:
      return CONST_REQUEST_TYPE_KYUYOSHOYOKOZATOROKU;
    case CONST_REQUEST_TYPE_NUM_12:
      return CONST_REQUEST_TYPE_JUSHOHENKOTSUKINTEATE;
    case CONST_REQUEST_TYPE_NUM_13:
      return CONST_REQUEST_TYPE_KEIHIKOZATOROKU;
    default:
      throw new Error("不正なrequestType");
  }
}

/**
 * 申請書ステータス番号を名称文字列に変換する
 * @param requestStatus
 * @returns
 */
export function cnvRequestStatusNum2Name(requestStatus: number): string {
  switch (requestStatus) {
    case CONST_REQUEST_STATUS_MISINSEI:
      return CONST_REQUEST_STATUS_NUM_0;
    case CONST_REQUEST_STATUS_SINSEITYU:
      return CONST_REQUEST_STATUS_NUM_1;
    case CONST_REQUEST_STATUS_SASIMODOSI:
      return CONST_REQUEST_STATUS_NUM_2;
    case CONST_REQUEST_STATUS_SYOUNINZUMI:
      return CONST_REQUEST_STATUS_NUM_3;
    case CONST_REQUEST_STATUS_TORISAGE:
      return CONST_REQUEST_STATUS_NUM_4;
    default:
      throw new Error("不正なrequestStatus");
  }
}

/**
 * 申請書ステータスの名称文字列を番号に変更する
 * @param requestStatus
 * @returns
 */
export function cnvRequestStatusName2Num(requestStatus: string): number {
  switch (requestStatus) {
    case CONST_REQUEST_STATUS_NUM_0:
      return CONST_REQUEST_STATUS_MISINSEI;
    case CONST_REQUEST_STATUS_NUM_1:
      return CONST_REQUEST_STATUS_SINSEITYU;
    case CONST_REQUEST_STATUS_NUM_2:
      return CONST_REQUEST_STATUS_SASIMODOSI;
    case CONST_REQUEST_STATUS_NUM_3:
      return CONST_REQUEST_STATUS_SYOUNINZUMI;
    case CONST_REQUEST_STATUS_NUM_4:
      return CONST_REQUEST_STATUS_TORISAGE;
    default:
      throw new Error("不正なrequestStatus");
  }
}

/**
 * 承認ステータス番号を名称文字列に変換する
 * @param approvalStatus
 * @returns
 */
export function cnvApprovalStatusNum2Name(approvalStatus: number): string {
  switch (approvalStatus) {
    case CONST_APPROVAL_STATUS_SYOUNIN:
      return CONST_APPROVAL_STATUS_NUM_1;
    case CONST_APPROVAL_STATUS_SASIMODOSI:
      return CONST_APPROVAL_STATUS_NUM_2;
    case CONST_APPROVAL_STATUS_JYOUISYOUNIN:
      return CONST_APPROVAL_STATUS_NUM_3;
    default:
      throw new Error("不正なapprovalStatus");
  }
}
/**
 * 承認ステータスの名称文字列を番号に変更する
 * @param approvalStatus
 * @returns
 */
export function cnvApprovalStatusName2Num(approvalStatus: string): number {
  switch (approvalStatus) {
    case CONST_APPROVAL_STATUS_NUM_1:
      return CONST_APPROVAL_STATUS_SYOUNIN;
    case CONST_APPROVAL_STATUS_NUM_2:
      return CONST_APPROVAL_STATUS_SASIMODOSI;
    case CONST_APPROVAL_STATUS_NUM_3:
      return CONST_APPROVAL_STATUS_JYOUISYOUNIN;
    default:
      throw new Error("不正なapprovalStatus");
  }
}

export const CONST_OP_REQUEST_TYPE = [
  { label: "仮払申請書", code: 1 },
  { label: "時間外残業申請書", code: 2 },
  { label: "リモートワーク申請書", code: 3 },
  { label: "出張申請書", code: 4 },
  { label: "接待申請書", code: 5 },
  { label: "社用車使用許可申請書", code: 6 },
  { label: "（仮払立替）経費精算書", code: 7 },
  { label: "休暇届", code: 8 },
  { label: "稟議書", code: 9 },
  { label: "休職願", code: 10 },
  { label: "給与賞与振込口座登録依頼書", code: 11 },
  { label: "住所変更及び通勤手当申請書", code: 12 },
  { label: "経費振込口座登録依頼書", code: 13 },
];

export const CONST_OP_REQUEST_STATUS = [
  { label: "未申請", code: 0 },
  { label: "申請中", code: 1 },
  { label: "差戻", code: 2 },
  { label: "承認済", code: 3 },
  { label: "取下", code: 4 },
];
export const CONST_OP_APPROVAL_STATUS = [
  { label: "承認", code: 1 },
  { label: "差戻", code: 2 },
  { label: "上位承認", code: 3 },
];

//申請書個別
//-------------------
//時間外残業申請書
//---------------
export const CONST_OP_OVERTIME_TYPE = [
  { label: "時間外残業", code: 1 },
  { label: "休日勤務", code: 2 },
];

//休暇届
//---------------
export const CONST_OP_HOLIDAY_PART_TYPE = [
  { label: "午前休", code: 1 },
  { label: "午後休", code: 2 },
];

export const CONST_OP_HOLIDAY_TYPE = [
  { label: "有給休暇", code: 1 },
  { label: "産前産後休暇", code: 2 },
  { label: "年次有給", code: 3 },
  { label: "代休・振替", code: 4 },
  { label: "生理休暇", code: 5 },
  { label: "転勤休暇", code: 6 },
  { label: "慶事休暇", code: 7 },
  { label: "特別休暇", code: 8 },
  { label: "その他", code: 9 },
  { label: "夏期休暇", code: 10 },
];

//経費精算書
//---------------
export const CONST_OP_EXPENSES_INVOICE = [
  // { label: "不要", code: 1 },
  // { label: "必要", code: 2 },
  // { label: "不明", code: 3 },
  { label: "T", code: 4 },
  { label: "特例", code: 5 },
  { label: "なし", code: 6 },
  { label: "８％", code: 7 },
  { label: "０％", code: 8 },
];
export const CONST_OP_EXPENSES_TYPE = [
  { label: "1	旅費交通費", code: 1 },
  { label: "2	交際費", code: 2 },
  { label: "3	会議費", code: 3 },
  { label: "4	通信費", code: 4 },
  { label: "5	租税公課", code: 5 },
  { label: "6	消耗品費", code: 6 },
  { label: "7	原価算入", code: 7 },
  { label: "8	その他", code: 8 },
];

//-------------------------
//勤怠関係
//-------------------------
//出勤時間、退勤時間チェック用　警告時間差
export const CONST_ATTENDANCE_TIME_DIFFERENCE: number = 10;

//-------

export const CONST_ATTENDANCE_TIME_DELIMITER_STR: string = "05:00:00";
export const CONST_ATTENDANCE_TIME_DELIMITER_NUM: number = 5;

export const CONST_ATTENDANCE_GETMODE_MINE: string = "MINE";
export const CONST_ATTENDANCE_GETMODE_VIEW: string = "VIEW";
export const CONST_ATTENDANCE_GETMODE_NORMAL: string = "NORMAL";

export const CONST_ATTENDANCE_POSTMODE_WORK: string = "WORK";
export const CONST_ATTENDANCE_POSTMODE_LEAVING: string = "LEAVING";
export const CONST_ATTENDANCE_POSTMODE_GOOUTSTART: string = "GOOUTSTART";
export const CONST_ATTENDANCE_POSTMODE_GOOUTEND: string = "GOOUTEND";

export const CONST_ATTENDANCE_POSTMODE_WORK_NUM: number = 1;
export const CONST_ATTENDANCE_POSTMODE_LEAVING_NUM: number = 2;
export const CONST_ATTENDANCE_POSTMODE_GOOUTSTART_NUM: number = 3;
export const CONST_ATTENDANCE_POSTMODE_GOOUTEND_NUM: number = 4;

export const CONST_ATTENDANCE_STATUS_MISYUKIN: string = "未出勤";
export const CONST_ATTENDANCE_STATUS_SYUKIN: string = "出勤中";
export const CONST_ATTENDANCE_STATUS_GAISYUTU: string = "外出中";
export const CONST_ATTENDANCE_STATUS_TAIKIN: string = "退勤済";

//-------------------------------
//顧客関係。
//-------------------------------
export const CONST_OP_CLIENT_TYPE = [
  { label: "広告", code: 1, colName: "c_client_flag_type_ad" },
  { label: "製造/製作", code: 2, colName: "c_client_flag_type_mfg" },
  { label: "印刷", code: 3, colName: "c_client_flag_type_print" },
  { label: "イベント", code: 4, colName: "c_client_flag_type_event" },
  { label: "通信/放送サービス", code: 5, colName: "c_client_flag_type_comm" },
  { label: "ファッション", code: 6, colName: "c_client_flag_type_fashion" },
  { label: "美容", code: 7, colName: "c_client_flag_type_beauty" },
  { label: "化粧品", code: 8, colName: "c_client_flag_type_cosmetic" },
  { label: "端末", code: 9, colName: "c_client_flag_type_device" },
  { label: "家電", code: 10, colName: "c_client_flag_type_appliance" },
  { label: "自動車/バイク", code: 11, colName: "c_client_flag_type_caretc" },
  { label: "音楽", code: 12, colName: "c_client_flag_type_music" },
  { label: "スポーツ", code: 13, colName: "c_client_flag_type_sport" },
  { label: "旅行", code: 14, colName: "c_client_flag_type_trip" },
  { label: "ゲーム", code: 15, colName: "c_client_flag_type_game" },
  { label: "書籍", code: 16, colName: "c_client_flag_type_book" },
  { label: "映画", code: 17, colName: "c_client_flag_type_movie" },
  { label: "食品", code: 18, colName: "c_client_flag_type_food" },
  { label: "飲料", code: 19, colName: "c_client_flag_type_drink" },
  { label: "外食", code: 20, colName: "c_client_flag_type_eatout" },
  { label: "運輸・観光", code: 21, colName: "c_client_flag_type_transpt" },
  { label: "教育", code: 22, colName: "c_client_flag_type_edu" },
  { label: "医療/医薬", code: 23, colName: "c_client_flag_type_medical" },
  { label: "証券", code: 24, colName: "c_client_flag_type_stock" },
  { label: "不動産", code: 25, colName: "c_client_flag_type_realty" },
  { label: "金融/保険", code: 26, colName: "c_client_flag_type_insure" },
  { label: "消費者金融", code: 27, colName: "c_client_flag_type_cfpa" },
  { label: "官庁", code: 28, colName: "c_client_flag_type_govtofc" },
  { label: "政治/宗教", code: 29, colName: "c_client_flag_type_politics" },
  { label: "マッチング", code: 30, colName: "c_client_flag_type_matching" },
  { label: "ギャンブル", code: 31, colName: "c_client_flag_type_gamble" },
  { label: "その他", code: 32, colName: "c_client_flag_type_others" },
];

//-------------------------------
//文書関係。
//-------------------------------
export const CONST_OP_DOC_ISSUER = [
  { label: "受領", code: 0 },
  { label: "発行", code: 1 },
];

export const CONST_DOC_ISSUER_JYURYOU: number = 0;
export const CONST_DOC_ISSUER_HAKKOU: number = 1;

export const CONST_DOC_ISSUER_NUM_1: string = "受領";
export const CONST_DOC_ISSUER_NUM_2: string = "発行";

/**
 * 文書発行フラグを名称文字列に変換する
 * @param approvalStatus
 * @returns
 */
export function cnvDocIssuerNum2Name(docIssuerNum: number): string {
  switch (docIssuerNum) {
    case CONST_DOC_ISSUER_JYURYOU:
      return CONST_DOC_ISSUER_NUM_1;
    case CONST_DOC_ISSUER_HAKKOU:
      return CONST_DOC_ISSUER_NUM_2;
    default:
      throw new Error("不正なDocIssuerNum");
  }
}
/**
 * 文書発行フラグの名称文字列を番号に変更する
 * @param approvalStatus
 * @returns
 */
export function cnvDocIssuerStr2Num(docIssuerStr: string): number {
  switch (docIssuerStr) {
    case CONST_DOC_ISSUER_NUM_1:
      return CONST_DOC_ISSUER_JYURYOU;
    case CONST_DOC_ISSUER_NUM_2:
      return CONST_DOC_ISSUER_HAKKOU;
    default:
      throw new Error("不正なDocIssuerStr");
  }
}

//-------------------
export const CONST_OP_DOC_TYPE = [
  { label: "請求書", code: 1 },
  { label: "領収書", code: 2 },
  { label: "注文書", code: 3 },
  { label: "見積書", code: 4 },
  { label: "納品書", code: 5 },
  { label: "契約書", code: 6 },
  { label: "公的機関への申請書", code: 7 },
  { label: "金融機関取引", code: 9 },
  { label: "その他", code: 8 },
];

export const CONST_DOC_TYPE_SEIKYUSYO: number = 1;
export const CONST_DOC_TYPE_RYOUSYUSYO: number = 2;
export const CONST_DOC_TYPE_TYUMONSYO: number = 3;
export const CONST_DOC_TYPE_MITUMORISYO: number = 4;
export const CONST_DOC_TYPE_NOUHINSYO: number = 5;
export const CONST_DOC_TYPE_KEIYAKUSYO: number = 6;
export const CONST_DOC_TYPE_SINSEISYO: number = 7;
export const CONST_DOC_TYPE_SONOTA: number = 8;
export const CONST_DOC_TYPE_KINYUKIKANTORIHIKI: number = 9;

export const CONST_DOC_TYPE_NUM_1: string = "請求書";
export const CONST_DOC_TYPE_NUM_2: string = "領収書";
export const CONST_DOC_TYPE_NUM_3: string = "注文書";
export const CONST_DOC_TYPE_NUM_4: string = "見積書";
export const CONST_DOC_TYPE_NUM_5: string = "納品書";
export const CONST_DOC_TYPE_NUM_6: string = "契約書";
export const CONST_DOC_TYPE_NUM_7: string = "公的機関への申請書";
export const CONST_DOC_TYPE_NUM_8: string = "その他";
export const CONST_DOC_TYPE_NUM_9: string = "金融機関取引";

/**
 * 文書種類番号を名称文字列に変換する
 * @param approvalStatus
 * @returns
 */
export function cnvDocTypeNum2Name(docTypeNum: number): string {
  switch (docTypeNum) {
    case CONST_DOC_TYPE_SEIKYUSYO:
      return CONST_DOC_TYPE_NUM_1;
    case CONST_DOC_TYPE_RYOUSYUSYO:
      return CONST_DOC_TYPE_NUM_2;
    case CONST_DOC_TYPE_TYUMONSYO:
      return CONST_DOC_TYPE_NUM_3;
    case CONST_DOC_TYPE_MITUMORISYO:
      return CONST_DOC_TYPE_NUM_4;
    case CONST_DOC_TYPE_NOUHINSYO:
      return CONST_DOC_TYPE_NUM_5;
    case CONST_DOC_TYPE_KEIYAKUSYO:
      return CONST_DOC_TYPE_NUM_6;
    case CONST_DOC_TYPE_SINSEISYO:
      return CONST_DOC_TYPE_NUM_7;
    case CONST_DOC_TYPE_SONOTA:
      return CONST_DOC_TYPE_NUM_8;
    case CONST_DOC_TYPE_KINYUKIKANTORIHIKI:
      return CONST_DOC_TYPE_NUM_9;
    default:
      throw new Error("不正なDocTypeNum");
  }
}
/**
 * 文書種類の名称文字列を番号に変更する
 * @param approvalStatus
 * @returns
 */
export function cnvDocTypeStr2Num(docTypeStr: string): number {
  switch (docTypeStr) {
    case CONST_DOC_TYPE_NUM_1:
      return CONST_DOC_TYPE_SEIKYUSYO;
    case CONST_DOC_TYPE_NUM_2:
      return CONST_DOC_TYPE_RYOUSYUSYO;
    case CONST_DOC_TYPE_NUM_3:
      return CONST_DOC_TYPE_TYUMONSYO;
    case CONST_DOC_TYPE_NUM_4:
      return CONST_DOC_TYPE_MITUMORISYO;
    case CONST_DOC_TYPE_NUM_5:
      return CONST_DOC_TYPE_NOUHINSYO;
    case CONST_DOC_TYPE_NUM_6:
      return CONST_DOC_TYPE_KEIYAKUSYO;
    case CONST_DOC_TYPE_NUM_7:
      return CONST_DOC_TYPE_SINSEISYO;
    case CONST_DOC_TYPE_NUM_8:
      return CONST_DOC_TYPE_SONOTA;
    case CONST_DOC_TYPE_NUM_9:
      return CONST_DOC_TYPE_KINYUKIKANTORIHIKI;
    default:
      throw new Error("不正なDocTypeStr");
  }
}

//-------------------

export const CONST_OP_DOC_MANAGETYPE = [
  { label: "経費精算", code: 43 },
  { label: "売上（媒体料）", code: 1 },
  { label: "売上（その他）", code: 2 },
  { label: "仕入（媒体料）", code: 3 },
  { label: "仕入（その他）", code: 4 },
  { label: "外注加工費", code: 5 },
  { label: "材料仕入", code: 6 },
  { label: "仕掛金", code: 7 },
  { label: "役員報酬", code: 8 },
  { label: "給料手当", code: 9 },
  { label: "従業員賞与", code: 10 },
  { label: "雑給/退職金", code: 11 },
  { label: "法定福利費", code: 12 },
  { label: "福利厚生費", code: 13 },
  { label: "広告宣伝費", code: 14 },
  { label: "支払手数料", code: 15 },
  { label: "旅費交通費", code: 16 },
  { label: "減価償却費", code: 17 },
  { label: "リース料", code: 18 },
  { label: "地代家賃", code: 19 },
  { label: "修繕費", code: 20 },
  { label: "事務用消耗品費", code: 21 },
  { label: "備品･消耗品費", code: 22 },
  { label: "通信費", code: 23 },
  { label: "水道光熱費", code: 24 },
  { label: "租税公課", code: 25 },
  { label: "接待交際費", code: 26 },
  { label: "保険料", code: 27 },
  { label: "諸会費", code: 28 },
  { label: "管理諸費", code: 29 },
  { label: "会議費", code: 30 },
  { label: "研修費", code: 31 },
  { label: "雑費", code: 32 },
  { label: "受取利息", code: 33 },
  { label: "受取配当金", code: 34 },
  { label: "業務受託収入", code: 35 },
  { label: "雑収入", code: 36 },
  { label: "支払利息", code: 37 },
  { label: "手形売却損", code: 38 },
  { label: "業務委託費", code: 39 },
  { label: "雑損失", code: 40 },
  { label: "特別損益（売却益）", code: 41 },
  { label: "特別損益（除却損）", code: 42 },
  { label: "銀行関係", code: 44 },
  { label: "売掛金", code: 45 },
];

export const CONST_DOC_MANAGETYPE_KEIHISEISAN: number = 43;
export const CONST_DOC_MANAGETYPE_URIAGE_BAITAI: number = 1;
export const CONST_DOC_MANAGETYPE_URIAGE_SONOTA: number = 2;
export const CONST_DOC_MANAGETYPE_SIIRE_BAITAI: number = 3;
export const CONST_DOC_MANAGETYPE_SIIRE_SONOTA: number = 4;
export const CONST_DOC_MANAGETYPE_GAITYUKAKOUHI: number = 5;
export const CONST_DOC_MANAGETYPE_ZAIRYOUSIIRE: number = 6;
export const CONST_DOC_MANAGETYPE_SIKAKARIKIN: number = 7;
export const CONST_DOC_MANAGETYPE_YAKUINNHOUSYU: number = 8;
export const CONST_DOC_MANAGETYPE_KYURYOTEATE: number = 9;
export const CONST_DOC_MANAGETYPE_JUUGYOINSHOYO: number = 10;
export const CONST_DOC_MANAGETYPE_ZAKKYUTAISHOKUKIN: number = 11;
export const CONST_DOC_MANAGETYPE_HOTEIFUKURIBI: number = 12;
export const CONST_DOC_MANAGETYPE_FUKURIKOSEIBI: number = 13;
export const CONST_DOC_MANAGETYPE_KOKOKUSENDENHI: number = 14;
export const CONST_DOC_MANAGETYPE_SHIHARAITESURYO: number = 15;
export const CONST_DOC_MANAGETYPE_RYOHIKOTSUHI: number = 16;
export const CONST_DOC_MANAGETYPE_GENKASHOKYAKUHI: number = 17;
export const CONST_DOC_MANAGETYPE_RISURYO: number = 18;
export const CONST_DOC_MANAGETYPE_JIDAIYACHIN: number = 19;
export const CONST_DOC_MANAGETYPE_SHUZENHI: number = 20;
export const CONST_DOC_MANAGETYPE_JIMUYOSHOHINHI: number = 21;
export const CONST_DOC_MANAGETYPE_BIHINSHOHINHI: number = 22;
export const CONST_DOC_MANAGETYPE_TSUSHINHI: number = 23;
export const CONST_DOC_MANAGETYPE_SUIDOKOUNETSUHI: number = 24;
export const CONST_DOC_MANAGETYPE_SOZEIKOKA: number = 25;
export const CONST_DOC_MANAGETYPE_SETAIKOSAISHI: number = 26;
export const CONST_DOC_MANAGETYPE_HOKENRYO: number = 27;
export const CONST_DOC_MANAGETYPE_SHOKAISHI: number = 28;
export const CONST_DOC_MANAGETYPE_KANRISHOHI: number = 29;
export const CONST_DOC_MANAGETYPE_KAIGISHI: number = 30;
export const CONST_DOC_MANAGETYPE_KENSHUHI: number = 31;
export const CONST_DOC_MANAGETYPE_ZAPPI: number = 32;
export const CONST_DOC_MANAGETYPE_UKETORIRISHI: number = 33;
export const CONST_DOC_MANAGETYPE_UKETORIHAITOKIN: number = 34;
export const CONST_DOC_MANAGETYPE_GYOMUJUTAKUSHUNYU: number = 35;
export const CONST_DOC_MANAGETYPE_ZASSHUNYU: number = 36;
export const CONST_DOC_MANAGETYPE_SHIHARAIRISHI: number = 37;
export const CONST_DOC_MANAGETYPE_TEGATABAIKYAKUSON: number = 38;
export const CONST_DOC_MANAGETYPE_GYOMUITAKUHI: number = 39;
export const CONST_DOC_MANAGETYPE_ZASSONSHITSU: number = 40;
export const CONST_DOC_MANAGETYPE_TOKUBETSUSONEK_BAIKYAKUEKI: number = 41;
export const CONST_DOC_MANAGETYPE_TOKUBETSUSONEKI_JOYAKUSON: number = 42;
export const CONST_DOC_MANAGETYPE_GINKOUKANKEI: number = 44;
export const CONST_DOC_MANAGETYPE_URIKAKEKIN: number = 45;

export const CONST_DOC_MANAGETYPE_NUM_43: string = "経費精算";
export const CONST_DOC_MANAGETYPE_NUM_1: string = "売上（媒体料）";
export const CONST_DOC_MANAGETYPE_NUM_2: string = "売上（その他）";
export const CONST_DOC_MANAGETYPE_NUM_3: string = "仕入（媒体料）";
export const CONST_DOC_MANAGETYPE_NUM_4: string = "仕入（その他）";
export const CONST_DOC_MANAGETYPE_NUM_5: string = "外注加工費";
export const CONST_DOC_MANAGETYPE_NUM_6: string = "材料仕入";
export const CONST_DOC_MANAGETYPE_NUM_7: string = "仕掛金";
export const CONST_DOC_MANAGETYPE_NUM_8: string = "役員報酬";
export const CONST_DOC_MANAGETYPE_NUM_9: string = "給料手当";
export const CONST_DOC_MANAGETYPE_NUM_10: string = "従業員賞与";
export const CONST_DOC_MANAGETYPE_NUM_11: string = "雑給/退職金";
export const CONST_DOC_MANAGETYPE_NUM_12: string = "法定福利費";
export const CONST_DOC_MANAGETYPE_NUM_13: string = "福利厚生費";
export const CONST_DOC_MANAGETYPE_NUM_14: string = "広告宣伝費";
export const CONST_DOC_MANAGETYPE_NUM_15: string = "支払手数料";
export const CONST_DOC_MANAGETYPE_NUM_16: string = "旅費交通費";
export const CONST_DOC_MANAGETYPE_NUM_17: string = "減価償却費";
export const CONST_DOC_MANAGETYPE_NUM_18: string = "リース料";
export const CONST_DOC_MANAGETYPE_NUM_19: string = "地代家賃";
export const CONST_DOC_MANAGETYPE_NUM_20: string = "修繕費";
export const CONST_DOC_MANAGETYPE_NUM_21: string = "事務用消耗品費";
export const CONST_DOC_MANAGETYPE_NUM_22: string = "備品･消耗品費";
export const CONST_DOC_MANAGETYPE_NUM_23: string = "通信費";
export const CONST_DOC_MANAGETYPE_NUM_24: string = "水道光熱費";
export const CONST_DOC_MANAGETYPE_NUM_25: string = "租税公課";
export const CONST_DOC_MANAGETYPE_NUM_26: string = "接待交際費";
export const CONST_DOC_MANAGETYPE_NUM_27: string = "保険料";
export const CONST_DOC_MANAGETYPE_NUM_28: string = "諸会費";
export const CONST_DOC_MANAGETYPE_NUM_29: string = "管理諸費";
export const CONST_DOC_MANAGETYPE_NUM_30: string = "会議費";
export const CONST_DOC_MANAGETYPE_NUM_31: string = "研修費";
export const CONST_DOC_MANAGETYPE_NUM_32: string = "雑費";
export const CONST_DOC_MANAGETYPE_NUM_33: string = "受取利息";
export const CONST_DOC_MANAGETYPE_NUM_34: string = "受取配当金";
export const CONST_DOC_MANAGETYPE_NUM_35: string = "業務受託収入";
export const CONST_DOC_MANAGETYPE_NUM_36: string = "雑収入";
export const CONST_DOC_MANAGETYPE_NUM_37: string = "支払利息";
export const CONST_DOC_MANAGETYPE_NUM_38: string = "手形売却損";
export const CONST_DOC_MANAGETYPE_NUM_39: string = "業務委託費";
export const CONST_DOC_MANAGETYPE_NUM_40: string = "雑損失";
export const CONST_DOC_MANAGETYPE_NUM_41: string = "特別損益（売却益）";
export const CONST_DOC_MANAGETYPE_NUM_42: string = "特別損益（除却損）";
export const CONST_DOC_MANAGETYPE_NUM_44: string = "銀行関係";
export const CONST_DOC_MANAGETYPE_NUM_45: string = "売掛金";

/**
 * 文書種別番号を名称文字列に変換する
 * @param approvalStatus
 * @returns
 */
export function cnvDocManageTypeNum2Name(docManageTypeNum: number): string {
  switch (docManageTypeNum) {
    case CONST_DOC_MANAGETYPE_KEIHISEISAN:
      return CONST_DOC_MANAGETYPE_NUM_43;
    case CONST_DOC_MANAGETYPE_URIAGE_BAITAI:
      return CONST_DOC_MANAGETYPE_NUM_1;
    case CONST_DOC_MANAGETYPE_URIAGE_SONOTA:
      return CONST_DOC_MANAGETYPE_NUM_2;
    case CONST_DOC_MANAGETYPE_SIIRE_BAITAI:
      return CONST_DOC_MANAGETYPE_NUM_3;
    case CONST_DOC_MANAGETYPE_SIIRE_SONOTA:
      return CONST_DOC_MANAGETYPE_NUM_4;
    case CONST_DOC_MANAGETYPE_GAITYUKAKOUHI:
      return CONST_DOC_MANAGETYPE_NUM_5;
    case CONST_DOC_MANAGETYPE_ZAIRYOUSIIRE:
      return CONST_DOC_MANAGETYPE_NUM_6;
    case CONST_DOC_MANAGETYPE_SIKAKARIKIN:
      return CONST_DOC_MANAGETYPE_NUM_7;
    // case CONST_DOC_MANAGETYPE_YAKUINNHOUSYU:
    //   return CONST_DOC_MANAGETYPE_NUM_8;
    // case CONST_DOC_MANAGETYPE_KYURYOTEATE:
    //   return CONST_DOC_MANAGETYPE_NUM_9;
    case CONST_DOC_MANAGETYPE_JUUGYOINSHOYO:
      return CONST_DOC_MANAGETYPE_NUM_10;
    case CONST_DOC_MANAGETYPE_ZAKKYUTAISHOKUKIN:
      return CONST_DOC_MANAGETYPE_NUM_11;
    case CONST_DOC_MANAGETYPE_HOTEIFUKURIBI:
      return CONST_DOC_MANAGETYPE_NUM_12;
    case CONST_DOC_MANAGETYPE_FUKURIKOSEIBI:
      return CONST_DOC_MANAGETYPE_NUM_13;
    case CONST_DOC_MANAGETYPE_KOKOKUSENDENHI:
      return CONST_DOC_MANAGETYPE_NUM_14;
    case CONST_DOC_MANAGETYPE_SHIHARAITESURYO:
      return CONST_DOC_MANAGETYPE_NUM_15;
    case CONST_DOC_MANAGETYPE_RYOHIKOTSUHI:
      return CONST_DOC_MANAGETYPE_NUM_16;
    case CONST_DOC_MANAGETYPE_GENKASHOKYAKUHI:
      return CONST_DOC_MANAGETYPE_NUM_17;
    case CONST_DOC_MANAGETYPE_RISURYO:
      return CONST_DOC_MANAGETYPE_NUM_18;
    case CONST_DOC_MANAGETYPE_JIDAIYACHIN:
      return CONST_DOC_MANAGETYPE_NUM_19;
    case CONST_DOC_MANAGETYPE_SHUZENHI:
      return CONST_DOC_MANAGETYPE_NUM_20;
    case CONST_DOC_MANAGETYPE_JIMUYOSHOHINHI:
      return CONST_DOC_MANAGETYPE_NUM_21;
    case CONST_DOC_MANAGETYPE_BIHINSHOHINHI:
      return CONST_DOC_MANAGETYPE_NUM_22;
    case CONST_DOC_MANAGETYPE_TSUSHINHI:
      return CONST_DOC_MANAGETYPE_NUM_23;
    case CONST_DOC_MANAGETYPE_SUIDOKOUNETSUHI:
      return CONST_DOC_MANAGETYPE_NUM_24;
    case CONST_DOC_MANAGETYPE_SOZEIKOKA:
      return CONST_DOC_MANAGETYPE_NUM_25;
    case CONST_DOC_MANAGETYPE_SETAIKOSAISHI:
      return CONST_DOC_MANAGETYPE_NUM_26;
    case CONST_DOC_MANAGETYPE_HOKENRYO:
      return CONST_DOC_MANAGETYPE_NUM_27;
    case CONST_DOC_MANAGETYPE_SHOKAISHI:
      return CONST_DOC_MANAGETYPE_NUM_28;
    case CONST_DOC_MANAGETYPE_KANRISHOHI:
      return CONST_DOC_MANAGETYPE_NUM_29;
    case CONST_DOC_MANAGETYPE_KAIGISHI:
      return CONST_DOC_MANAGETYPE_NUM_30;
    case CONST_DOC_MANAGETYPE_KENSHUHI:
      return CONST_DOC_MANAGETYPE_NUM_31;
    case CONST_DOC_MANAGETYPE_ZAPPI:
      return CONST_DOC_MANAGETYPE_NUM_32;
    case CONST_DOC_MANAGETYPE_UKETORIRISHI:
      return CONST_DOC_MANAGETYPE_NUM_33;
    case CONST_DOC_MANAGETYPE_UKETORIHAITOKIN:
      return CONST_DOC_MANAGETYPE_NUM_34;
    case CONST_DOC_MANAGETYPE_GYOMUJUTAKUSHUNYU:
      return CONST_DOC_MANAGETYPE_NUM_35;
    case CONST_DOC_MANAGETYPE_ZASSHUNYU:
      return CONST_DOC_MANAGETYPE_NUM_36;
    case CONST_DOC_MANAGETYPE_SHIHARAIRISHI:
      return CONST_DOC_MANAGETYPE_NUM_37;
    case CONST_DOC_MANAGETYPE_TEGATABAIKYAKUSON:
      return CONST_DOC_MANAGETYPE_NUM_38;
    case CONST_DOC_MANAGETYPE_GYOMUITAKUHI:
      return CONST_DOC_MANAGETYPE_NUM_39;
    case CONST_DOC_MANAGETYPE_ZASSONSHITSU:
      return CONST_DOC_MANAGETYPE_NUM_40;
    case CONST_DOC_MANAGETYPE_TOKUBETSUSONEK_BAIKYAKUEKI:
      return CONST_DOC_MANAGETYPE_NUM_41;
    case CONST_DOC_MANAGETYPE_TOKUBETSUSONEKI_JOYAKUSON:
      return CONST_DOC_MANAGETYPE_NUM_42;
    case CONST_DOC_MANAGETYPE_GINKOUKANKEI:
      return CONST_DOC_MANAGETYPE_NUM_44;
    case CONST_DOC_MANAGETYPE_URIKAKEKIN:
      return CONST_DOC_MANAGETYPE_NUM_45;
    default:
      throw new Error("不正なDocManageTypeNum");
  }
}
/**
 * 文書種別の名称文字列を番号に変更する
 * @param approvalStatus
 * @returns
 */
export function cnvDocManageTypeStr2Num(docManageTypeStr: string): number {
  switch (docManageTypeStr) {
    case CONST_DOC_MANAGETYPE_NUM_43:
      return CONST_DOC_MANAGETYPE_KEIHISEISAN;
    case CONST_DOC_MANAGETYPE_NUM_1:
      return CONST_DOC_MANAGETYPE_URIAGE_BAITAI;
    case CONST_DOC_MANAGETYPE_NUM_2:
      return CONST_DOC_MANAGETYPE_URIAGE_SONOTA;
    case CONST_DOC_MANAGETYPE_NUM_3:
      return CONST_DOC_MANAGETYPE_SIIRE_BAITAI;
    case CONST_DOC_MANAGETYPE_NUM_4:
      return CONST_DOC_MANAGETYPE_SIIRE_SONOTA;
    case CONST_DOC_MANAGETYPE_NUM_5:
      return CONST_DOC_MANAGETYPE_GAITYUKAKOUHI;
    case CONST_DOC_MANAGETYPE_NUM_6:
      return CONST_DOC_MANAGETYPE_ZAIRYOUSIIRE;
    case CONST_DOC_MANAGETYPE_NUM_7:
      return CONST_DOC_MANAGETYPE_SIKAKARIKIN;
    // case CONST_DOC_MANAGETYPE_NUM_8:
    //   return CONST_DOC_MANAGETYPE_YAKUINNHOUSYU;
    // case CONST_DOC_MANAGETYPE_NUM_9:
    //   return CONST_DOC_MANAGETYPE_KYURYOTEATE;
    case CONST_DOC_MANAGETYPE_NUM_10:
      return CONST_DOC_MANAGETYPE_JUUGYOINSHOYO;
    case CONST_DOC_MANAGETYPE_NUM_11:
      return CONST_DOC_MANAGETYPE_ZAKKYUTAISHOKUKIN;
    case CONST_DOC_MANAGETYPE_NUM_12:
      return CONST_DOC_MANAGETYPE_HOTEIFUKURIBI;
    case CONST_DOC_MANAGETYPE_NUM_13:
      return CONST_DOC_MANAGETYPE_FUKURIKOSEIBI;
    case CONST_DOC_MANAGETYPE_NUM_14:
      return CONST_DOC_MANAGETYPE_KOKOKUSENDENHI;
    case CONST_DOC_MANAGETYPE_NUM_15:
      return CONST_DOC_MANAGETYPE_SHIHARAITESURYO;
    case CONST_DOC_MANAGETYPE_NUM_16:
      return CONST_DOC_MANAGETYPE_RYOHIKOTSUHI;
    case CONST_DOC_MANAGETYPE_NUM_17:
      return CONST_DOC_MANAGETYPE_GENKASHOKYAKUHI;
    case CONST_DOC_MANAGETYPE_NUM_18:
      return CONST_DOC_MANAGETYPE_RISURYO;
    case CONST_DOC_MANAGETYPE_NUM_19:
      return CONST_DOC_MANAGETYPE_JIDAIYACHIN;
    case CONST_DOC_MANAGETYPE_NUM_20:
      return CONST_DOC_MANAGETYPE_SHUZENHI;
    case CONST_DOC_MANAGETYPE_NUM_21:
      return CONST_DOC_MANAGETYPE_JIMUYOSHOHINHI;
    case CONST_DOC_MANAGETYPE_NUM_22:
      return CONST_DOC_MANAGETYPE_BIHINSHOHINHI;
    case CONST_DOC_MANAGETYPE_NUM_23:
      return CONST_DOC_MANAGETYPE_TSUSHINHI;
    case CONST_DOC_MANAGETYPE_NUM_24:
      return CONST_DOC_MANAGETYPE_SUIDOKOUNETSUHI;
    case CONST_DOC_MANAGETYPE_NUM_25:
      return CONST_DOC_MANAGETYPE_SOZEIKOKA;
    case CONST_DOC_MANAGETYPE_NUM_26:
      return CONST_DOC_MANAGETYPE_SETAIKOSAISHI;
    case CONST_DOC_MANAGETYPE_NUM_27:
      return CONST_DOC_MANAGETYPE_HOKENRYO;
    case CONST_DOC_MANAGETYPE_NUM_28:
      return CONST_DOC_MANAGETYPE_SHOKAISHI;
    case CONST_DOC_MANAGETYPE_NUM_29:
      return CONST_DOC_MANAGETYPE_KANRISHOHI;
    case CONST_DOC_MANAGETYPE_NUM_30:
      return CONST_DOC_MANAGETYPE_KAIGISHI;
    case CONST_DOC_MANAGETYPE_NUM_31:
      return CONST_DOC_MANAGETYPE_KENSHUHI;
    case CONST_DOC_MANAGETYPE_NUM_32:
      return CONST_DOC_MANAGETYPE_ZAPPI;
    case CONST_DOC_MANAGETYPE_NUM_33:
      return CONST_DOC_MANAGETYPE_UKETORIRISHI;
    case CONST_DOC_MANAGETYPE_NUM_34:
      return CONST_DOC_MANAGETYPE_UKETORIHAITOKIN;
    case CONST_DOC_MANAGETYPE_NUM_35:
      return CONST_DOC_MANAGETYPE_GYOMUJUTAKUSHUNYU;
    case CONST_DOC_MANAGETYPE_NUM_36:
      return CONST_DOC_MANAGETYPE_ZASSHUNYU;
    case CONST_DOC_MANAGETYPE_NUM_37:
      return CONST_DOC_MANAGETYPE_SHIHARAIRISHI;
    case CONST_DOC_MANAGETYPE_NUM_38:
      return CONST_DOC_MANAGETYPE_TEGATABAIKYAKUSON;
    case CONST_DOC_MANAGETYPE_NUM_39:
      return CONST_DOC_MANAGETYPE_GYOMUITAKUHI;
    case CONST_DOC_MANAGETYPE_NUM_40:
      return CONST_DOC_MANAGETYPE_ZASSONSHITSU;
    case CONST_DOC_MANAGETYPE_NUM_41:
      return CONST_DOC_MANAGETYPE_TOKUBETSUSONEK_BAIKYAKUEKI;
    case CONST_DOC_MANAGETYPE_NUM_42:
      return CONST_DOC_MANAGETYPE_TOKUBETSUSONEKI_JOYAKUSON;
    case CONST_DOC_MANAGETYPE_NUM_44:
      return CONST_DOC_MANAGETYPE_GINKOUKANKEI;
    case CONST_DOC_MANAGETYPE_NUM_45:
      return CONST_DOC_MANAGETYPE_URIKAKEKIN;

    default:
      throw new Error("不正なDocManageTypeStr");
  }
}
//-------------------------------
//文書の権限関係。
//-------------------------------
/**
 * 重要文書にアクセス可能な人の配列
 */
export const CONST_DOC_AUTH_IMPORTANT = [
  { staffid: 15, staffname: "田中真哉" },
  { staffid: 11, staffname: "石原岳人" },
  { staffid: 19, staffname: "神野秀二郎" },
  //--------テスト用
  // { staffid: 2, staffname: "阿部悠友" },
  // { staffid: 6, staffname: "佐藤和博" },
];
/**
 * 機密文書にアクセス可能な人の配列
 */
export const CONST_DOC_AUTH_CONFIDENTIAL = [
  { staffid: 4, staffname: "葛谷健一" },
  { staffid: 27, staffname: "山本晋治" },
  { staffid: 28, staffname: "岩崎一樹" },
  { staffid: 43, staffname: "阿児慎一郎" },
  { staffid: 10, staffname: "成澤壮一郎" },
  //--------テスト用
  // { staffid: 4, staffname: "葛谷健一" },
  // // { staffid: 5, staffname: "亀岡靖" },
];
/**
 * 文書種別番号と、文書種別、権限番号（1:一般文書;2:重要文書;3:機密文書;）の構造体。
 */
export const CONST_DOC_AUTH_STRUCTURE = [
  { managTypeNum: 1, manageType: "売上（媒体料）", authType: 1 },
  { managTypeNum: 2, manageType: "売上（その他）", authType: 1 },
  { managTypeNum: 3, manageType: "仕入（媒体料）", authType: 1 },
  { managTypeNum: 4, manageType: "仕入（その他）", authType: 1 },
  { managTypeNum: 5, manageType: "外注加工費", authType: 1 },
  { managTypeNum: 6, manageType: "材料仕入", authType: 1 },
  { managTypeNum: 7, manageType: "仕掛金", authType: 1 },
  { managTypeNum: 8, manageType: "役員報酬", authType: 3 },
  { managTypeNum: 9, manageType: "給料手当", authType: 3 },
  { managTypeNum: 10, manageType: "従業員賞与", authType: 3 },
  { managTypeNum: 11, manageType: "雑給/退職金", authType: 3 },
  { managTypeNum: 12, manageType: "法定福利費", authType: 3 },
  { managTypeNum: 13, manageType: "福利厚生費", authType: 3 },
  { managTypeNum: 14, manageType: "広告宣伝費", authType: 2 },
  { managTypeNum: 15, manageType: "支払手数料", authType: 2 },
  { managTypeNum: 16, manageType: "旅費交通費", authType: 2 },
  { managTypeNum: 17, manageType: "減価償却費", authType: 2 },
  { managTypeNum: 18, manageType: "リース料", authType: 2 },
  { managTypeNum: 19, manageType: "地代家賃", authType: 2 },
  { managTypeNum: 20, manageType: "修繕費", authType: 2 },
  { managTypeNum: 21, manageType: "事務用消耗品費", authType: 2 },
  { managTypeNum: 22, manageType: "備品･消耗品費", authType: 2 },
  { managTypeNum: 23, manageType: "通信費", authType: 2 },
  { managTypeNum: 24, manageType: "水道光熱費", authType: 2 },
  { managTypeNum: 25, manageType: "租税公課", authType: 2 },
  { managTypeNum: 26, manageType: "接待交際費", authType: 2 },
  { managTypeNum: 27, manageType: "保険料", authType: 2 },
  { managTypeNum: 28, manageType: "諸会費", authType: 2 },
  { managTypeNum: 29, manageType: "管理諸費", authType: 2 },
  { managTypeNum: 30, manageType: "会議費", authType: 2 },
  { managTypeNum: 31, manageType: "研修費", authType: 2 },
  { managTypeNum: 32, manageType: "雑費", authType: 2 },
  { managTypeNum: 33, manageType: "受取利息", authType: 3 },
  { managTypeNum: 34, manageType: "受取配当金", authType: 3 },
  { managTypeNum: 35, manageType: "業務受託収入", authType: 3 },
  { managTypeNum: 36, manageType: "雑収入", authType: 3 },
  { managTypeNum: 37, manageType: "支払利息", authType: 3 },
  { managTypeNum: 38, manageType: "手形売却損", authType: 3 },
  { managTypeNum: 39, manageType: "業務委託費", authType: 3 },
  { managTypeNum: 40, manageType: "雑損失", authType: 3 },
  { managTypeNum: 41, manageType: "特別損益（売却益）", authType: 3 },
  { managTypeNum: 42, manageType: "特別損益（除却損）", authType: 3 },
  { managTypeNum: 43, manageType: "経費精算", authType: 2 },
  { managTypeNum: 44, manageType: "銀行関係", authType: 3 },
  { managTypeNum: 45, manageType: "売掛金", authType: 2 },
];

/**
 * 各時間を受け取り、ステータスを表す文字列を返す
 * @param
 * @returns
 */
export function getAttendanceStatusStr(
  timeWork: string | null,
  timeLeaving: string | null,
  timeGooutStart: string | null,
  timeGooutEnd: string | null
): string {
  if (
    timeWork == null &&
    timeLeaving == null &&
    timeGooutStart == null &&
    timeGooutEnd == null
  ) {
    return CONST_ATTENDANCE_STATUS_MISYUKIN;
  }
  if (
    timeWork != null &&
    timeLeaving == null &&
    timeGooutStart != null &&
    timeGooutEnd == null
  ) {
    return CONST_ATTENDANCE_STATUS_GAISYUTU;
  }
  if (timeWork != null && timeLeaving == null) {
    return CONST_ATTENDANCE_STATUS_SYUKIN;
  }
  if (timeWork != null && timeLeaving != null) {
    return CONST_ATTENDANCE_STATUS_TAIKIN;
  }
  if (timeWork == null && timeLeaving != null) {
    return CONST_ATTENDANCE_STATUS_TAIKIN;
  }

  throw new Error("不正な状態");
}

/**
 * 性別を推測するメソッド。
 * 男性、不明なら1、女性なら2を返す。
 * @param name
 * @returns
 */
export function predictGender(name: string | null): number {
  if (name == null || typeof name !== "string") {
    return 0;
  }

  // 名前の最後の文字を取得
  const lastChar = name.slice(-1);

  // 男性の名前に多い文字での推測
  const maleIndicators = ["郎", "夫", "男", "彦", "介", "一"];
  if (maleIndicators.includes(lastChar)) {
    return 1;
  }

  // 女性の名前に多い文字での推測
  const femaleIndicators = [
    "子",
    "美",
    "奈",
    "香",
    "恵",
    "花",
    "菜",
    "穂",
    "華",
    "幸",
    "央",
    "希",
  ];
  if (femaleIndicators.includes(lastChar)) {
    return 2;
  }

  // 不明な場合、男性
  return 1;
}
