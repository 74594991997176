export class EntityRequest {
  public c_request_id: number | null = null;
  public c_staff_id: number | null = null;
  public c_request_type: number | null = null;
  public c_request_datetime: Date | null = null;
  public c_request_status: number | null = null;
  public c_request_name: string | null = null;
  public c_request_1_approval: number | null = null;
  public c_request_1_approval_datetime: Date | null = null;
  public c_request_1_approval_status: number | null = null;
  public c_request_2_approval: number | null = null;
  public c_request_2_approval_datetime: Date | null = null;
  public c_request_2_approval_status: number | null = null;
  public c_request_3_approval: number | null = null;
  public c_request_3_approval_datetime: Date | null = null;
  public c_request_3_approval_status: number | null = null;
  public c_request_flag_del: number | null = null;
  public c_request_reg_user: string | null = null;
  public c_request_reg_at: Date | null = null;
  public c_request_upd_user: string | null = null;
  public c_request_upd_at: Date | null = null;

  /**
   * 稟議書のコメント
   */
  public comment: string | null = null;
  /**
   * 稟議書の承認結果
   */
  public aprvResult: number | null = null;

  /**
   * 経費精算書の対象年月
   */
  public targetDate: Date | null = null;
}
