import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { DtoRequestsGetReq } from "./abst/DtoRequestsGetReq";
import { DtoApiPager } from "./abst/DtoApiPager";

export class DtoRequestsGetReqImple extends DtoRequestsGetReq {
  public pager = new DtoApiPager();

  public requestidstart: string | null = null;
  public requestidend: string | null = null;
  /**
   * 申請種類
   */
  public requesttype: number | null = null;
  /**
   * ステータス
   */
  public requeststatsu: number | null = null;
  /**
   * 承認者　社員IDID
   */
  public authorizerid: number | null = null;
  /**
   *申請者　社員ID
   */
  public applicantid: number | null = null;
  /**
   * 対象年月
   */
  public targetDate: Date | null = null;

  public IsValidImple(chkPager: boolean): string[] {
    let res: string[] = new Array();
    //ページャー用入力値チェック
    if (chkPager) {
      this.pager.IsValid4Pager(res);
    } else {
      res = res.concat(super.IsValid());
    }

    return res;
  }
}
