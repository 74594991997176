//-------------------------------
//基本設定。
//-------------------------------
/**
 * デバッグスイッチ
 */
export const CONST_BASE_IS_DEBAG = true;

/**
 * 一覧デフォ設定 1あたり件数
 */
export const CONST_LIST_DEFO_COUNT = 10;

/**
 * 新着情報数の定期更新間隔 msで指定。
 */
export const CONST_REGULARLY_ALERTCOUNT = 5 * 60 * 1000;
// export const CONST_REGULARLY_ALERTCOUNT = 5 * 1000;

/**
 * APIのURL
 */
export const CONST_URL_API =
  // "https://4d02ci05i6.execute-api.ap-northeast-1.amazonaws.com/1_0_0"; //テスト環境
  // "https://4d02ci05i6.execute-api.ap-northeast-1.amazonaws.com/1_0_0"; //開発環境（PIPLINEのステージング環境）
  "https://4d02ci05i6.execute-api.ap-northeast-1.amazonaws.com/1_0_0"; //devset1
// "https://4d02ci05i6.execute-api.ap-northeast-1.amazonaws.com/1_0_0"; //devset2
// "https://4d02ci05i6.execute-api.ap-northeast-1.amazonaws.com/1_0_0"; //devset3
