
import { Prop, Options, Vue, Watch } from "vue-property-decorator";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import {
  CONST_OP_TAXRATE,
  CONST_OP_EXPENSES_INVOICE,
  CONST_OP_EXPENSES_TYPE,
} from "mediadept-common/src/common/constant/Constants";
import { EntityRequestExpensesList } from "mediadept-common/src/common/entity/EntityRequestExpensesList";

@Options({
  components: {
    vSelect,
  },
})
export default class Mo_expenses_row extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  @Prop({ required: true })
  item: EntityRequestExpensesList;
  @Prop({ required: true })
  isDisable: boolean;

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  opTaxtate = CONST_OP_TAXRATE;
  opInvoice = CONST_OP_EXPENSES_INVOICE;
  opType = CONST_OP_EXPENSES_TYPE;
  //----------------------
  //モーダル用プロパティ
  //----------------------

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    //
  }
  /**
   * 再計算イベントが発生した事を上位のコンポーネントに伝えるイベント発生
   */
  @Watch("item.cnvPrice")
  eventCalc() {
    //再計算イベント発生
    //-----------------------
    this.$emit("reCalc");
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * 金額セル入力時の処理
   * カンマを消す
   * @param obj
   */
  kanmaDel(obj: any) {
    // //カンマを消す
    this.item.cnvPrice = obj.target.value.replace(/,/g, "");
    if (this.item.cnvPrice == "0") this.item.cnvPrice = "";
  }
  /**
   * 金額セル入力時の処理
   * カンマを付ける
   * @param obj
   */
  kanmaIns(obj: any) {
    let val: string = obj.target.value;

    //うっかり入力しているかもしれないカンマを消す
    val = val.replace(/,/g, "");
    //整数に変換したのち文字列に戻す
    //この時点で数字とマイナス記号だけが残る
    let num = "" + parseInt(val);
    //正規表現で桁区切りするための良く見かける関数的な何か
    //変数 num の中身が、桁区切りされる
    while (num != (num = num.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
    //numに入っている値が数値じゃないときは空白とする
    if (isNaN(parseInt(num))) num = "";
    //桁区切りした結果（変数 num）でテキストボックスの中身を書き換える
    // obj.target.value = num;
    this.item.cnvPrice = num;
  }

  /**
   * クリック時の選択処理
   */
  async selectRow() {
    this.item.isSelect = true;
    this.$emit("selected", this.item.c_expenses_rownum);
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
}
