import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { CONST_BREAKDOWNS, CONST_ITEM, CONST_SUBJECTS } from "../constant/Constants";
import { EntityClient } from "../entity/EntityClient";
import { EntitySearchProject } from "../entity/EntitySearchProject";
import { EntitySearchSales } from "../entity/EntitySearchSales";
import { DtoApiPager } from "./abst/DtoApiPager";


export class DtoSearchProjectGetRes extends EntitySearchProject {

    public items: EntitySearchProject[] = [];
    public pager = new DtoApiPager();
    public exitems: Exitem[] = [];

    public async setEximtes(clientmap: Map<number, string>) {

        let tmp: Exitem;
        for (let index = 0; index < this.items.length; index++) {
            tmp = new Exitem();

            tmp.c_project_id = this.items[index].c_project_id;
            tmp.c_project_number = this.items[index].c_project_number;
            tmp.c_project_name = this.items[index].c_project_name;
            tmp.c_client_name = this.items[index].c_client_name;
            tmp.c_project_sponsor_name = this.items[index].c_project_sponsor_name;
            tmp.c_staff_name = this.items[index].c_staff_name;
            tmp.c_project_cont = this.items[index].c_project_cont;

            this.exitems.push(tmp);
        }


    }

}

export class Exitem extends EntitySearchProject {
    // public c_sales_item_name: string | null = null;
    // public c_sales_subject_name: string | null = null;
    // public c_sales_breakdown_name: string | null = null;

}

