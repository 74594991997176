import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { DtoFileReportsGetReq } from "./abst/DtoFileReportsGetReq";
import { DtoApiPager } from "./abst/DtoApiPager";
import { DtoAuthorityRes } from "./DtoAuthorityRes";

/**
 * 帳票の出力処理、集計処理用のDTOクラス。
 */
export class DtoFileReportsGetReqImple extends DtoFileReportsGetReq {
  public authData: DtoAuthorityRes | null = null;
  //----------------------------
  //集計処理用。
  //----------------------------
  /**
   * 集計処理を実施するかの判定フラグ。
   */
  public isAggregatePross: number = 0;

  //----------------------------
  //出力処理用。
  //----------------------------

  public cover: boolean | null = null;
  public coverN: number | null = null;
  public type: string | null = null;
  public type2: string | null = null;
  public month: number | null = null;
  public year: number | null = null;
  public outputqueue_code: string | null = null;

  // 受注情報検索

  //public staffId: number | null = null;
  public salesPrice_start: number | null = null;
  public salesPrice_end: number | null = null;
  public supplyPrice_start: number | null = null;
  public supplyPrice_end: number | null = null;
  public proRemarks: string | null = null;
  public proFreeWord: string | null = null;
  public proCont: string | null = null;

  // 売上検索
  public projectNo_start: number | null = null;
  public projectNo_end: number | null = null;
  public projectName: string | null = null;
  public proClientid: number | null = null;
  public projectSponsorName: string | null = null;
  public staffId: string | null = null;
  public claimNo_start: number | null = null;
  public claimNo_end: number | null = null;
  public salesIssueDate_start: Date | null = null;
  public salesIssueDate_end: Date | null = null;
  public salesMonth_start: Date | null = null;
  public salesMonth_end: Date | null = null;
  public salesItem: number | null = null;
  public salesBreakdown: number | null = null;
  public salesClientid: number | null = null;
  public SalesRemarks: string | null = null;
  public VariationType: number | null = null;
  public ClientGenre: number | null = null;
  public ItemType: number | null = null;
  public salesSubject: number | null = null; //TODO:削除
  public subjectSales: number | null = null;

  // 仕入検索
  // public projectNo_start: number | null = null;
  // public projectNo_end: number | null = null;
  // public projectName: string | null = null;
  // public proClientid: number | null = null;
  // public projectSponsorName: string | null = null;
  // public staffId: string | null = null;
  // public claimNo_start: number | null = null;
  // public claimNo_end: number | null = null;
  public supplyMonth_start: Date | null = null;
  public supplyMonth_end: Date | null = null;
  public supplyItem: number | null = null;
  public supplyBreakdown: number | null = null;
  public supplierid: number | null = null;
  public projectType: number | null = null;
  public supplyRemarks: string | null = null;
  public supplyDateExpPayment_start: Date | null = null;
  public supplyDateExpPayment_end: Date | null = null;
  // public VariationType: number | null = null;
  // public ClientGenre: number | null = null;
  // public ItemType: number | null = null;
  public chkClaim: number | null = null;
  public supplySubject: number | null = null; //TODO:削除
  public subjectSupply: number | null = null;

  //商品マスタ一覧用
  public itemManagNumber: string | null = null;
  public itemName: string | null = null;
  public itemType: string | null = null;
  public itemOwner: string | null = null;
  public itemArea: string | null = null;
  public itemTerm: string | null = null;

  // 未承認一覧
  // public claimNo_start: number | null = null;
  // public claimNo_end: number | null = null;
  public ClaimDate_start: Date | null = null;
  public ClaimDate_end: Date | null = null;
  public Clientid: number | null = null;
  public ClaimTitle: string | null = null;
  public ClaimSponsorName: string | null = null;
  public ClaimPayLimit_start: Date | null = null;
  public ClaimPayLimit_end: Date | null = null;
  public StaffId: number | null = null;
  public ClaimStatus: number | null = null;
  //public flagSales: number | null = null;

  // 入金確定表
  // public claimNo_start: number | null = null;
  // public claimNo_end: number | null = null;
  // public ClaimDate_start: Date | null = null;
  // public ClaimDate_end: Date | null = null;
  // public Clientid: number | null = null;
  // public ClaimTitle: string | null = null;
  // public ClaimSponsorName: string | null = null;
  // public ClaimPayLimit_start: Date | null = null;
  // public ClaimPayLimit_end: Date | null = null;
  // public StaffId: number | null = null;
  public PaymentStatus: number | null = null;

  // 請求No検索
  public ClaimNo: number | null = null;

  //請求一覧
  //-------------------
  /**
   * 商品種別指定：1_ビルボード
   */
  itemType1_builbord: number = 0;
  /**
   * 商品種別指定：2_ポスタージャック
   */
  itemType2_posterjack: number = 0;
  /**
   * 商品種別指定：3_商業施設
   */
  itemType3_ComFacility: number = 0;
  /**
   * 商品種別指定：4_デジタルビルボード
   */
  itemType4_digitalBuilBoard: number = 0;
  /**
   * 商品種別指定：5_交通広告
   */
  itemType5_transportation: number = 0;
  /**
   * 商品種別指定：6_その他
   */
  itemType6_others: number = 0;
  /**
   * 0:売上なし;1:売上あり;
   */
  public flagSales: number | null = null;

  //勤怠一覧
  //-------------------
  public staffNo_start: string | null = null;
  public staffNo_end: string | null = null;
  public staffName: string | null = null;
  /**
   * 対象月をyyyy-MMで指定
   */
  public attendancemo: string | null = null;

  //出勤表個別用
  /**
   * 社員IDを指定
   */
  public attendanceid: number | null = null;

  // 請求書のPDFを文書に登録
  //-------------------------
  public claimid: number | null = null;

  public IsValidImple(chkPager: boolean): string[] {
    let res: string[] = new Array();

    return res;
  }
}
